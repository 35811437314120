<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
      class="mb-4"
    />
  </teleport-wrapper>

  <teleport-wrapper
    to="#mainTitle"
  >
    {{ t('breadcrumbs.broadcast.page_creation') }}
  </teleport-wrapper>

  <app-card-item
    v-if="true"
    class="p-4 max-w-screen-md"
  >
    <broadcast-form
      form-mode="create"
      :additional-errors="formErrors"
      @submitted="handleSubmit"
    />
  </app-card-item>
</template>

<script setup>
import {
  ref,
  computed,
  onBeforeMount,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import { useSpinnerStore } from '@shared/store/spinner'
import {
  fetchPointOfInterest,
  createBroadcastPage,
} from '@shared/http/api'
import breadcrumbBroadcastPage from '@extranet/breadcrumbs/broadcast_page'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import BroadcastForm from '@extranet/components/resources/broadcast_page/BroadcastPageForm.vue'

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const spinnerStore = useSpinnerStore()
const flashStore = useFlashesStore()
const authStore = useAuthStore()

const pointOfInterest = ref({})

// ----------- FORM SUBMIT -----------

const broadcastPageFormSubmitting = ref(false)
const formErrors = ref({})

function handleSubmit(attributes) {
  broadcastPageFormSubmitting.value = true

  const params = {
    data: {
      type: 'broadcastpages',
      attributes: {
        ...attributes,
        ...{
          point_of_interest_id: parseInt(route.params.poi_id, 10),
        },
      },
    },
  }

  createBroadcastPage(params)
    .then((response) => {
      broadcastPageFormSubmitting.value = false

      router.push({ name: 'show-points-of-interest', params: { id: response.data.data.attributes.point_of_interest_id } })

      flashStore.addFlash({
        message: t('broadcast.notifications.created'),
        type: 'success',
      })
    })
    .catch((e) => {
      if (e.response?.data?.errors) {
        formErrors.value = e.response.data.errors
      }
    })
    .finally(() => {
      spinnerStore.disable()
      broadcastPageFormSubmitting.value = false
    })
}

// ----------- FETCH DATA -----------

function fetchResource(id) {
  fetchPointOfInterest(id)
    .then((response) => {
      pointOfInterest.value = response.data.data

      if (
        (!pointOfInterest.value.attributes.owned_by_auth_user)
          || (!authStore.user?.authorizations?.broadcast_pages?.create)
          || (pointOfInterest.value.relationships?.broadcast_page !== null)
      ) {
        router.push({ name: 'dashboard' })

        flashStore.addFlash({
          message: t('exceptions.403'),
          type: 'danger',
        })
      }
    })
}

// ---------- BREADCRUMBS ----------

const { create } = breadcrumbBroadcastPage()

const breadcrumbs = computed(() => (
  create(pointOfInterest.value)
))

// ---------- LIFECYCLE ----------

onBeforeMount(() => {
  fetchResource(route.params.poi_id)
})
</script>
