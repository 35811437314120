<template>
  <div class="flex justify-between mb-4">
    <p class="font-bold text-xl">
      {{ t('tutorials.extranet.begin.butler.steps.institution.subtitle.part_1') }}
    </p>

    <app-tutorial-button
      :tutorials="tutorials"
    />
  </div>

  <institutions-index
    :per-page="4"
    @selected="handleSelect"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import { useSpinnerStore } from '@shared/store/spinner'
import { applyPointOfInterest } from '@shared/http/api'
import AppTutorialButton from '@extranet/components/ui/help/AppTutorialButton.vue'
import HelpInstitutionHotel from '@extranet/components/begin/help/HelpInstitutionHotel.vue'
import InstitutionsIndex from '@extranet/components/resources/institution_employee/request_form/institutions/InstitutionsIndex.vue'

const emits = defineEmits([
  'submitted',
])

const authStore = useAuthStore()
const flashesStore = useFlashesStore()
const spinnerStore = useSpinnerStore()

// ---------- TUTORIALS ----------

const { t } = useI18n()

const tutorials = computed(() => ([
  {
    menuItem: t('tutorials.extranet.begin.hotelier.steps.employees.title'),
    component: HelpInstitutionHotel,
  },
]))

// ---------- SELECTED ----------

function handleSelect(resource) {
  spinnerStore.enable()

  applyPointOfInterest(resource.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('institutions_employees.apply.success'),
        type: 'success',
      })

      handleRefresh()
    })
    .finally(() => {
      spinnerStore.disable()
    })
}

// ---------- REFRESH MODAL ----------

async function handleRefresh() {
  await authStore.getAuthenticatedUser()
    .then(() => {
      emits('submitted')
    })
}
</script>
