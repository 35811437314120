import { computed, ref } from 'vue'
import { set } from 'lodash'
import { useI18n } from 'vue-i18n'

import { useSpinnerStore } from '@shared/store/spinner'
import {
  fetchPointOfInterest,
  checkDuplicatesPointsOfInterest,
} from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import useResource from '@shared/hooks/resources/resource'

export default function usePoiCreateForm(props, emits) {
  const spinnerStore = useSpinnerStore()
  const { t, locale } = useI18n()
  const { relevantEnoughScore } = usePointOfInterest()
  const {
    handleSubmit,
    invalid,
    additionalErrors,
  } = useForm(props, emits)
  const { resourceExists } = useResource()

  // ---------- STEPS ----------

  const STEP_CHECK_DUPLICATE = 1
  const STEP_FULL_FORM = 2

  // If resource already exists, init step at 2
  const initialStep = resourceExists(props.resource)
    ? STEP_FULL_FORM
    : STEP_CHECK_DUPLICATE

  const step = ref(initialStep)

  const isStepCheckDuplicate = computed(() => (
    step.value === STEP_CHECK_DUPLICATE
  ))

  const isStepFullForm = computed(() => (
    step.value === STEP_FULL_FORM
  ))

  // ---------- DUPLICATES ----------

  const duplicates = ref([])
  const selectedDuplicateId = ref(null)
  const selectedDuplicateResource = ref(null)
  const duplicatesModal = ref()
  const duplicatesModalDisplayed = ref(false)

  function selectDuplicate(id) {
    selectedDuplicateId.value = id

    spinnerStore.enable()

    fetchPointOfInterest(id)
      .then((response) => {
        selectedDuplicateResource.value = response.data.data
      })
      .finally(() => {
        spinnerStore.disable()
        duplicatesModal.value?.$refs.contentWrapper.scrollTo({ top: 0, behavior: 'smooth' })
      })
  }

  // Duplicates not relevant enough will be ignored
  const duplicatesRelevantEnough = computed(() => (
    duplicates.value.filter((duplicate) => (
      duplicate.attributes.relevance >= relevantEnoughScore
    ))
  ))

  async function checkForDuplicates() {
    // Retrieve params to submit, or "false" if form is invalid
    const params = await handleSubmit(false)

    // Proceed if params are valid
    if (params) {
      spinnerStore.enable()

      // Adapt translated params' keys
      set(params, 'title', params.title?.[locale.value])
      set(params, 'address.line1', params.address?.line1?.[locale.value])
      set(params, 'address.line2', params.address?.line2?.[locale.value])
      set(params, 'address.city', params.address?.city?.[locale.value])
      set(params, 'address.dependent_locality', params.address?.dependent_locality?.[locale.value])
      set(params, 'address.administrative_area', params.address?.administrative_area?.[locale.value])

      checkDuplicatesPointsOfInterest({
        data: {
          attributes: params,
        },
      })
        .then((response) => {
          duplicates.value = response.data.data ?? []
          handleDuplicatesChecked()
        })
        .catch((e) => {
          if (e.response?.data?.errors) {
            additionalErrors.value = e.response.data.errors
          }
        })
        .finally(() => {
          spinnerStore.disable()
        })
    }
  }

  function handleDuplicatesChecked() {
    if (duplicatesRelevantEnough.value.length > 0) {
      duplicatesModalDisplayed.value = true
    } else {
      continueCreation()
        .then()
    }
  }

  async function continueCreation() {
    handleDuplicatesModalClose()
    step.value = STEP_FULL_FORM
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function handleDuplicatesModalClose() {
    duplicatesModalDisplayed.value = false
    selectedDuplicateId.value = null
    selectedDuplicateResource.value = null
  }

  // ---------- SUBMIT ----------

  const submitLabel = computed(() => (
    isStepCheckDuplicate.value
      ? t('common.actions.check')
      : t('common.actions.save')
  ))

  function onSubmit(e) {
    if (isStepCheckDuplicate.value) {
      e.preventDefault()

      // Check for duplicates before going to next step
      checkForDuplicates()
        .then()
    } else {
      handleSubmit()
    }
  }

  return {
    selectedDuplicateId,
    selectedDuplicateResource,
    duplicatesModalDisplayed,
    selectDuplicate,
    duplicatesRelevantEnough,
    continueCreation,
    handleDuplicatesModalClose,
    invalid,
    additionalErrors,
    isStepCheckDuplicate,
    isStepFullForm,
    submitLabel,
    onSubmit,
  }
}
