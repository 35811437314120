<template>
  <template v-if="isCurrentStep">
    <form-group
      rules="required|email"
      name="email"
      type="email"
      margins="mb-1"
      :initial-value="authStore.user.attributes.email"
    />

    <form-group
      rules="required|phone"
      name="phone"
      type="tel"
      margins="mb-1"
    />
  </template>

  <template v-else>
    <form-group
      name="email"
      type="hidden"
    />

    <form-group
      name="phone"
      type="hidden"
    />
  </template>
</template>

<script setup>
import { computed } from 'vue'

import { useAuthStore } from '@shared/store/auth'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // Step object which contains the following struct:
  //   step: 0,                       // step counter
  //   name: 'Name',                  // step name
  //   component: StepDocuments,      // component to display
  //   props: {},                     // component's props
  //   emits: {},                     // component's events
  //   icon: {                        // icon to display in form wizard nav
  //     library: 'coolicon',         // icon's library used
  //     name: 'file_blank_outline',  // icon's name
  //     iconStyle: null,             // icon's style
  //   },
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

const authStore = useAuthStore()

// ---------- CURRENT STEP ----------

const isCurrentStep = computed(() => (
  props.step.step === props.currentStep
))
</script>
