<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>
  <user-form
    :resource="userResource"
    :additional-errors="userFormErrors"
    :submitting="userFormSubmitting"
    :mode="mode"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import { fromJS } from 'immutable'

import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import { updateCurrentUser } from '@shared/http/api'
import useAppRouter from '@shared/hooks/router'
import useResource from '@shared/hooks/resources/resource'
import breadcrumbUser from '@extranet/breadcrumbs/user'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import UserForm from '@extranet/components/resources/user/UserForm.vue'

const route = useRoute()
const { t } = useI18n()
const { getImageFromResource } = useResource()
const appRouter = useAppRouter()
const authStore = useAuthStore()
const flashesStore = useFlashesStore()

// ---------- USER ----------

const user = ref(authStore.user)
const mode = route.query.mode

const avatar = getImageFromResource(user.value)

const userResource = computed(() => {
  const updatedUser = fromJS(user.value).toJS()

  if (!avatar) {
    // Provide an empty avatar form field
    updatedUser.relationships.images = [{
      attributes: {
        name: t('validation.attributes.profile_picture'),
      },
    }]
  }

  return updatedUser
})

// ---------- FORM ----------

const userFormSubmitting = ref(false)
const userFormErrors = ref({})

function handleSubmit(attributes) {
  if (!userFormSubmitting.value) {
    userFormSubmitting.value = true
    const params = {
      data: {
        type: 'user',
        attributes,
      },
    }

    updateCurrentUser(params)
      .then(async () => {
        await authStore.getAuthenticatedUser()
          .then(() => {
            appRouter.back()
            flashesStore.addFlash({
              message: t('users.update.success'),
              type: 'success',
            })
          })
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          userFormErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        userFormSubmitting.value = false
      })
  }
}

// ---------- BREADCRUMBS ----------

const { profileEdit } = breadcrumbUser()

const breadcrumbs = computed(() => (
  profileEdit()
))
</script>
