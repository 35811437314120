<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <div class="w-full lg:w-3/5">
    <div
      class="bg-white rounded-2lg shadow p-4 lg:mb-5"
    >
      <form
        novalidate
        @submit.prevent="handleSubmit"
      >
        <form-global-errors />

        <fieldset :disabled="formSubmitting">
          <p class="mb-4">
            {{ t('users.profile.delete_account.warning') }}
          </p>

          <p
            v-if="authStore.user.attributes.has_easy_transac_account"
            class="mb-4"
          >
            {{ t('users.profile.delete_account.easy_transac') }}
          </p>

          <p class="mb-4">
            {{ t('users.profile.delete_account.instructions') }}
          </p>

          <form-group
            name="deletion_confirmation"
            rules="required"
            :label="false"
          />

          <app-button
            feature="confirm"
            type="submit"
            :disabled="formSubmitting || invalid"
            :loading="formSubmitting"
          />
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useForm as useVVForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useLoadingStore } from '@shared/store/loading'
import { useFlashesStore } from '@shared/store/flashes'
import { deleteUser } from '@shared/http/api'
import breadcrumbUser from '@extranet/breadcrumbs/user'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const authStore = useAuthStore()
const loadingStore = useLoadingStore()
const flashesStore = useFlashesStore()

// ---------- DELETE ----------

const router = useRouter()
const { t } = useI18n()
const {
  setErrors,
  values,
  errors,
} = useVVForm()

const formSubmitting = ref(false)
const success = ref(false)

const invalid = computed(() => (
  Object.keys(errors.value).length > 0
))

function handleSubmit() {
  if (!formSubmitting.value) {
    formSubmitting.value = true

    const params = {
      data: {
        type: 'users',
        attributes: { ...values },
      },
    }

    deleteUser(params)
      .then(() => {
        success.value = true

        // Disconnect current user
        loadingStore.startLoading()
        authStore.signOutFromApi()
          .then(async () => {
            await router.push({ name: 'login' })
          })
          .finally(() => {
            loadingStore.reset()
          })

        flashesStore.addFlash({
          message: t('users.profile.delete_account.success'),
          type: 'success',
        })
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          setErrors({ ...e.response.data.errors })
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- BREADCRUMBS ----------

const { profileDelete } = breadcrumbUser()

const breadcrumbs = computed(() => (
  profileDelete()
))
</script>
