<template>
  <form
    id="claim-form"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <div class="grid grid-cols-2 gap-4">
      <form-group
        rules="required"
        name="company_type"
        type="select"
        :label="t('validation.attributes.company_type')"
        :form-control-props="{
          options: claimCompanyTypesOptions,
        }"
      />

      <form-group
        rules="required|phone"
        name="phone"
        type="tel"
        :placeholder="t('form.placeholders.institutions-phone')"
        margins="mb-1"
      />
    </div>

    <div
      class="grid grid-cols-2 gap-4"
    >
      <form-group
        hidden
        rules="required"
        name="country"
        type="select"
        :label="t('validation.attributes.country')"
        :initial-value="authStore.user.relationships?.address?.attributes?.country"
        :form-control-props="{
          options: claimCountriesOptions,
        }"
      />

      <form-group
        hidden
        rules="required"
        name="company_type"
        type="select"
        :label="t('validation.attributes.company_type')"
        :form-control-props="{
          options: claimCompanyTypesOptions,
        }"
      />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <form-group
        rules="required|email"
        name="email"
        type="email"
        margins="mb-1"
        :initial-value="authStore.user.attributes.email"
      />
    </div>

    <div class="text-left mt-2">
      <form-group
        v-if="companyTypeValue"
        rules="required"
        name="supporting_document"
        type="file"
        :hint="hintTranslation"
        :form-control-props="{
          mimeTypesAccepted: documentMimeTypes
        }"
      />
    </div>

    <form-group
      rules="required"
      name="claim_confirmation"
      type="checkbox"
      flex-direction="row"
      margins="my-4"
      :label="t('points_of_interest.claim.confirm_checkbox')"
      :form-label-props="{
        required: false,
      }"
    />

    <teleport-wrapper
      to="#claim-form-buttons"
    >
      <app-button
        form="claim-form"
        feature="confirm"
        type="submit"
        :loading="submitting"
        :disabled="submitting || invalid"
      />
    </teleport-wrapper>
  </form>
</template>

<script setup>
import {
  computed,
  onMounted,
} from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import useDocuments from '@extranet/hooks/documents'
import useForm from '@shared/hooks/form/form'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { t } = useI18n()
const authStore = useAuthStore()

const {
  claimCountriesOptions,
  claimCompanyTypesOptions,
} = useSelectOptions()

const props = defineProps({
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Point of interest selected
  poi: {
    type: Object,
    default: () => {},
  },
})

const emits = defineEmits([
  'submitted',
])

// ---------- FORM ----------

const {
  handleSubmit,
  form,
  invalid,
} = useForm(props, { emits })

const countryValue = computed(() => (
  form.values.country
))

const companyTypeValue = computed(() => (
  form.values.company_type
))

// ---------- HINT ----------

function getHintTranslation() {
  return t(`claims.form_wizard.documents.${countryValue.value}.${companyTypeValue.value}`)
}

const hintTranslation = computed(() => (
  getHintTranslation()
))

// ---------- RULES ----------

const { documentMimeTypes } = useDocuments()

onMounted(() => {
  form.values.phone_country_code = props.poi.relationships.address.attributes.country
})
</script>
