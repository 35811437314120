<template>
  <institutions-index
    @selected="handleInstitutionSelected"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import { useSpinnerStore } from '@shared/store/spinner'
import { useFlashesStore } from '@shared/store/flashes'
import {
  applyPointOfInterest,
} from '@shared/http/api'
import InstitutionsIndex from '@extranet/components/resources/institution_employee/request_form/institutions/InstitutionsIndex.vue'

const emits = defineEmits([
  'applied',
])

const flashesStore = useFlashesStore()
const spinnerStore = useSpinnerStore()
const { t } = useI18n()

function handleInstitutionSelected(resource) {
  spinnerStore.enable()

  applyPointOfInterest(resource.id)
    .then((response) => {
      flashesStore.addFlash({
        message: t('institutions_employees.apply.success'),
        type: 'success',
      })

      emits('applied', response.data.data)
    })
    .finally(() => {
      spinnerStore.disable()
    })
}
</script>
