import { ref } from 'vue'
import { uniqBy } from 'lodash'

import { useFiltersStore } from '@shared/store/filters'
import { fetchPointOfInterestCategories } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'

export default function useCategories() {
  const filtersStore = useFiltersStore()
  const { getAttributeTranslation } = useLocale()

  // ---------- CATEGORIES ----------

  const initialCategoriesSearch = ref(true)
  const category = ref(null)

  // Return categories options used in select control,
  // based on categories resources
  function formatCategoriesOptions(categories) {
    return categories.map((categoryResource) => ({
      label: getAttributeTranslation(categoryResource.attributes?.label),
      value: categoryResource.id,
    }))
  }

  function handleCategoriesSearch(searchKeywords) {
    return new Promise((resolve) => {
      let categoryOptions = []

      if (initialCategoriesSearch.value) {
        initialCategoriesSearch.value = false

        if (filtersStore.filters.pointsOfInterest.point_of_interest_category_id) {
          // Provide an option with the resource's relationship value
          categoryOptions.push(formatCategoriesOptions([filtersStore.filters.pointsOfInterest.point_of_interest_category_id]))
        }
      }

      fetchPointOfInterestCategories({
        'filter[label]': searchKeywords,
        'order': 'alpha',
      })
        .then((e) => {
          // Get the new options from the response
          categoryOptions = categoryOptions.concat(formatCategoriesOptions(e.data.data))

          // Avoid updating var after each select change
          if (category.value) {
            category.value = e.data.data
          }
        })
        .finally(() => {
          resolve(uniqBy(categoryOptions, 'value'))
        })
    })
  }

  return {
    initialCategoriesSearch,
    category,
    handleCategoriesSearch,
  }
}
