<template>
  <app-overlay
    show
  >
    <app-modal
      :with-close-button="false"
    >
      <!-- Title -->
      {{ t('users.profile.fill_country') }}

      <!-- Form -->
      <form
        novalidate
        @submit.prevent="onSubmit"
      >
        <!-- Errors -->
        <form-global-errors
          class="mb-5"
        />

        <form-group
          name="address.id"
          type="hidden"
        />

        <!-- Country select -->
        <form-group
          ref="countryFormGroup"
          rules="required"
          name="address.country"
          type="select"
          class="mb-52"
          :form-control-props="{
            options: countries,
          }"
        >
          <template #control-vue-multiselect-option="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>
          <template #control-vue-multiselect-singlelabel="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>
        </form-group>

        <!-- Submit -->
        <app-button
          type="submit"
          :disabled="submitting"
          :loading="submitting"
          feature="save"
        />
      </form>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import { updateCurrentUser } from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import useGeoloc from '@shared/hooks/geoloc'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const { t } = useI18n()
const router = useRouter()
const { countries } = useGeoloc()
const authStore = useAuthStore()
const flashesStore = useFlashesStore()

// ---------- FORM ----------

const submitting = ref(false)
const formErrors = ref({})
const countryFormGroup = ref()

const {
  handleSubmit,
} = useForm({ additionalErrors: formErrors })

async function onSubmit() {
  const fieldsToSubmit = await handleSubmit()

  if (fieldsToSubmit && !submitting.value) {
    submitting.value = true

    const params = {
      data: {
        type: 'users',
        attributes: fieldsToSubmit,
      },
    }

    updateCurrentUser(params)
      .then(async () => {
        await authStore.getAuthenticatedUser()
          .then(() => {
            flashesStore.addFlash({
              message: t('users.update.success'),
              type: 'success',
            })

            // Once country is defined, redirect user to subscriptions page if it's needed
            if (authStore.user.attributes.should_choose_plan) {
              router.push({ name: 'subscriptions' })
            }
          })
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        submitting.value = false
      })
  }
}

// Let country select's options open
watch(countryFormGroup, () => {
  countryFormGroup.value.$refs.formControlRef.$refs.multiselect.open()
})

</script>
