<template>
  <div class="mx-auto max-w-lg">
    <div
      v-if="success && !spinnerStore.isActive"
    >
      <app-section-title
        :title="t('user_credential_verifications.verify.sent.title')"
        class="mb-3.5"
      />

      <p class="mb-2">
        {{ t('user_credential_verifications.verify.sent.email_1', { email: credentialSent }) }}
      </p>

      <p class="mb-3">
        {{ t('user_credential_verifications.verify.sent.email_2') }}
      </p>

      <app-button
        emphasis="low"
        :label="t('user_credential_verifications.verify.sent.again')"
        whitespace-classes="whitespace-normal"
        width="full"
        class="mb-3"
        @click="sendCredentialVerification(credentialSent)"
      />

      <app-button
        emphasis="low"
        :label="t('user_credential_verifications.verify.sent.refresh')"
        whitespace-classes="whitespace-normal"
        width="full"
        class="mb-3"
        @click="refreshUser"
      />

      <app-button
        emphasis="low"
        :label="t('user_credential_verifications.verify.sent.correct')"
        whitespace-classes="whitespace-normal"
        width="full"
        @click="resetForm"
      />
    </div>

    <div
      v-else-if="!spinnerStore.isActive"
    >
      <div>
        <!-- Warning title -->
        <app-section-title
          :title="t('exceptions.error_texts.credential_not_verified.title')"
          class="mb-3.5"
        />

        <!-- Verification form -->
        <form novalidate>
          <!-- Email -->
          <div class="mb-5">
            <form-group
              name="email"
              rules="email"
              type="email"
              :initial-value="authStore.user?.attributes?.email"
              margins="mb-1"
            />

            <app-button
              emphasis="low"
              :label="t('exceptions.error_texts.credential_not_verified.verify_mail')"
              whitespace-classes="whitespace-normal"
              @click="sendCredentialVerification(values.email)"
            />
          </div>

          <app-separator
            :text="t('auth.messages.or')"
            class="my-4"
          />

          <!-- Phone -->
          <div>
            <form-group
              name="phone"
              rules="phone"
              type="tel"
              :initial-value="authStore.user?.attributes?.phone"
              margins="mb-1"
            />

            <app-button
              emphasis="low"
              :label="t('exceptions.error_texts.credential_not_verified.verify_phone')"
              whitespace-classes="whitespace-normal"
              @click="sendCredentialVerification(values.phone)"
            />
          </div>
        </form>

        <!-- Logout -->
        <app-button
          feature="logout"
          :hide-icon="false"
          emphasis="low"
          class="mt-10"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'

import { useAuthStore } from '@shared/store/auth'
import { useSpinnerStore } from '@shared/store/spinner'
import {
  sendCredentialVerification as apiSendCredentialVerification,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import useLogout from '@shared/hooks/auth/logout'

const { t } = useI18n()
const spinnerStore = useSpinnerStore()
const authStore = useAuthStore()

const { logout } = useLogout({
  auth_route_name: 'login',
})

const { values, setErrors } = useForm()
const credentialSent = ref() // Value of the credential sent for verification

const success = ref(false)

function sendCredentialVerification(value) {
  credentialSent.value = value

  spinnerStore.enable()

  // Create a new verification procedure
  apiSendCredentialVerification({
    data: {
      attributes: {
        credential: value,
      },
    },
  }).then(() => {
    success.value = true
  }).catch((e) => {
    if (e.response?.data?.errors) {
      setErrors({ ...e.response.data.errors })
    }
    resetForm()
  }).finally(() => {
    spinnerStore.disable()
  })
}

// Refresh authenticated user to check if credentials have been verified
async function refreshUser() {
  spinnerStore.enable()
  resetForm()
  await authStore.getAuthenticatedUser()
  spinnerStore.disable()
}

// Reset the initial form state
function resetForm() {
  success.value = false
  credentialSent.value = null
}
</script>
