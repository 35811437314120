<template>
  <point-of-interest-show
    v-if="pointOfInterest && !loading"
    :resource="pointOfInterest"
    :destroy-callback="handleDestroy"
  />
  <point-of-interest-show-empty v-else-if="loading" />
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useFlashesStore } from '@shared/store/flashes'
import {
  fetchPointOfInterest,
  destroyPointOfInterest,
} from '@shared/http/api'
import PointOfInterestShow from '@app/components/resources/point_of_interest/PointOfInterestShow.vue'
import PointOfInterestShowEmpty from '@app/components/resources/point_of_interest/PointOfInterestShowEmpty.vue'
import useAppRouter from '@shared/hooks/router'

const route = useRoute()
const appRouter = useAppRouter()
const { t } = useI18n()
const flashesStore = useFlashesStore()

const pointOfInterest = ref(null)
const loading = ref(false)

function fetchPoi() {
  loading.value = true
  const { id } = route.params

  fetchPointOfInterest(id)
    .then((response) => {
      pointOfInterest.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

function handleDestroy() {
  destroyPointOfInterest(pointOfInterest.value.id).then(() => {
    appRouter.back()
    flashesStore.addFlash({
      message: t('points_of_interest.destroy.success'),
      type: 'success',
    })
  })
}

fetchPoi()
</script>
