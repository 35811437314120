<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:w-1/4 mr-4 mb-4">
      <stepper-wrapper
        :steps="steps"
        :active-step="activeStep"
      />

      <div>
        <slot name="remindMeLater" />
      </div>
    </div>

    <div class="w-full md:w-3/4 md:px-4">
      <div v-if="activeComponent">
        <component
          :is="activeComponent"
          v-on="activeComponent.emits"
        />
      </div>

      <step-done
        v-else
      />
    </div>
  </div>
</template>

<script setup>
import { computed, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import StepDone from '@extranet/components/begin/steps/done/StepDone.vue'
import StepInstitutionTouristOffice from '@extranet/components/begin/steps/institution/StepInstitutionTouristOffice.vue'
import StepperWrapper from '@extranet/components/tutorials/stepper/StepperWrapper.vue'

const emits = defineEmits([
  'submitted',
])

const { t } = useI18n()
const authStore = useAuthStore()

// ---------- STEPS ----------

const steps = shallowRef([
  {
    stepNumber: 0,
    stepState: t('tutorials.extranet.begin.tourist_office_employee.steps.institution.step_state'),
    stepNext: t('tutorials.extranet.begin.tourist_office_employee.steps.institution.step_next'),
    stepTitle: t('tutorials.extranet.begin.tourist_office_employee.steps.institution.title'),
    stepDescription: t('tutorials.extranet.begin.tourist_office_employee.steps.institution.description'),
    component: StepInstitutionTouristOffice,
    emits: {
      submitted: handleSubmit,
    },
  },
])

const currentStep = computed(() => (
  steps.value.find(
    (step) => step.stepState === authStore.user?.attributes.tutorial_state,
  )
))

const activeStep = computed(() => (
  currentStep.value?.stepNumber ?? 99
))

// ---------- COMPONENT ----------

const activeComponent = computed(() => (
  currentStep.value?.component ?? null
))

// ---------- REFRESH MODAL ----------

function handleSubmit() {
  emits('submitted')
}
</script>
