<template>
  <form
    id="contest-form"
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors />

    <app-alert
      design="warning"
      :title="t('claims.notice.already_claimed.title')"
      class="my-4"
    >
      {{ t('claims.notice.already_claimed.description') }}
    </app-alert>

    <div class="grid grid-cols-2 gap-4">
      <form-group
        rules="required"
        name="contestant_country"
        type="select"
        :label="t('validation.attributes.country')"
        :initial-value="authStore.user.relationships?.address.attributes.country"
        :form-control-props="{
          options: claimCountriesOptions,
        }"
      />

      <form-group
        rules="required"
        name="contestant_company_type"
        type="select"
        :label="t('validation.attributes.company_type')"
        :form-control-props="{
          options: claimCompanyTypesOptions,
        }"
      />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <form-group
        rules="required"
        name="contestant_last_name"
        type="text"
        :initial-value="authStore.user.attributes.last_name"
      />

      <form-group
        rules="required"
        name="contestant_first_name"
        type="text"
        :initial-value="authStore.user.attributes.first_name"
      />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <form-group
        rules="required|phone"
        name="contestant_phone"
        type="tel"
        :initial-value="authStore.user.attributes.phone"
      />

      <form-group
        rules="required|email"
        name="contestant_email"
        type="email"
        :initial-value="authStore.user.attributes.email"
      />
    </div>

    <form-group
      rules="required"
      name="contestant_message"
      type="textarea"
      :form-control-props="{
        rows: 6,
      }"
    />

    <teleport-wrapper
      to="#claim-form-buttons"
    >
      <app-button
        form="contest-form"
        feature="confirm"
        type="submit"
        :loading="submitting"
        :disabled="submitting || invalid"
      />
    </teleport-wrapper>
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useForm from '@shared/hooks/form/form'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import { useAuthStore } from '@shared/store/auth'

const props = defineProps({
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

// ---------- AUTH USER ----------

const authStore = useAuthStore()

// ---------- FORM ----------

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })

function onSubmit() {
  handleSubmit()
}

// ---------- COUNTRIES ----------

const { t } = useI18n()

const {
  claimCountriesOptions,
  claimCompanyTypesOptions,
} = useSelectOptions()
</script>
