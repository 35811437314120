<template>
  <div class="grid gap-4 grid-cols-1 lg:grid-cols-4">
    <app-card-item class="p-4 lg:col-span-3">
      <template v-if="mode === 'profile'">
        <form-group
          name="name"
          error-label-i18n-key="nickname"
          :label="t('validation.attributes.nickname')"
          type="text"
        />

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
          <form-group
            name="first_name"
            type="text"
          />

          <form-group
            name="last_name"
            type="text"
          />
        </div>

        <!-- Address -->
        <form-group
          name="address.id"
          type="hidden"
        />

        <form-group
          name="address.autocomplete"
          :submittable="false"
          type="select"
          error-label-i18n-key="line1"
          :label="t('validation.attributes.line1')"
          :initial-value="resource?.relationships?.address?.attributes?.line1?.[locale]"
          :form-control-props="{
            filterable: false,
            options: handleAddressSearch,
            minChars: 3,
          }"
          @input="handleAutocompleteInput"
          @changed="handleAddressChange"
        >
          <template #control-vue-multiselect-option="option">
            <div>
              <template v-if="option.icon">
                <!-- set a key to force icon to re-render when options change -->
                <font-awesome-icon
                  :key="option.icon"
                  :icon="option.icon"
                />
                &nbsp;
              </template>
              <strong>
                {{ option.label }}
              </strong>

              <template v-if="option.subLabel">
                <br>
                <em class="text-sm">
                  {{ option.subLabel }}
                </em>
              </template>
            </div>
          </template>
        </form-group>

        <form-group
          :name="`address.line1.${locale}`"
          type="hidden"
        />

        <form-group
          :name="`address.line2.${locale}`"
          type="text"
          @changed="resetCoords"
        />

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
          <form-group
            name="address.zip"
            type="text"
            @changed="resetCoords"
          />

          <form-group
            :name="`address.city.${locale}`"
            type="text"
            @changed="resetCoords"
          />
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
          <form-group
            :name="`address.dependent_locality.${locale}`"
            type="text"
            @changed="resetCoords"
          />

          <form-group
            :name="`address.administrative_area.${locale}`"
            type="text"
            @changed="resetCoords"
          />
        </div>

        <form-group
          name="address.country"
          type="select"
          :form-control-props="{
            options: countries,
          }"
          @changed="resetCoords"
        >
          <template #control-vue-multiselect-option="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>
          <template #control-vue-multiselect-singlelabel="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>
        </form-group>

        <form-group
          name="address.latitude"
          type="hidden"
        />

        <form-group
          name="address.longitude"
          type="hidden"
        />

        <form-group
          rules="email"
          name="contact.email"
          :label="t('validation.attributes.contact_email')"
          :hint="t('form.hints.profile.contact_email')"
          type="email"
        />

        <form-group
          rules="phone"
          name="contact.phone"
          :label="t('validation.attributes.contact_phone')"
          :hint="t('form.hints.profile.contact_phone')"
          type="tel"
        />
      </template>

      <!-- Password form -->
      <template v-if="mode === 'password'">
        <form-group
          v-if="props.resource.attributes.has_password"
          name="current_password"
          type="password"
          rules="required"
          :form-control-props="{
            autocomplete: 'new-password'
          }"
        />

        <form-group
          :label="t('validation.attributes.new_password')"
          rules="required"
          name="password"
          type="password"
          hide-errors
          :form-control-props="{
            autocomplete: 'new-password'
          }"
        >
          <template #hint>
            <form-password-hint-list
              :password="form.values.password"
            />
          </template>
        </form-group>

        <form-group
          :label="t('validation.attributes.new_password_confirmation')"
          name="password_confirmation"
          type="password"
          rules="confirmed:@password"
          :form-control-props="{
            autocomplete: 'new-password'
          }"
        />
      </template>

      <!-- Devise form -->
      <template v-if="mode === 'currency'">
        <form-group
          name="currency_id"
          rules="required"
          type="select"
          :form-control-props="{
            filterable: false,
            options: handleCurrenciesSearch,
          }"
        />
      </template>

      <!-- Locale form -->
      <template v-if="mode === 'locale'">
        <form-group
          name="locale"
          type="select"
          :form-control-props="{
            filterable: false,
            options: staticLocalesOptionsTranslated,
          }"
        />
      </template>

      <!-- Bank account form -->
      <template v-if="isModeBankAccount">
        <add-bank-account-form
          :bank-data="resource.attributes"
          @submitted="updateBankAccount"
        />
      </template>

      <!-- Tips enabled form -->
      <template v-if="mode === 'tips_enabled'">
        <form-group
          name="tips_enabled"
          type="switch"
        />
      </template>
    </app-card-item>

    <!-- Profile picture form -->
    <div v-if="mode === 'profile'">
      <app-card-item class="p-4 h-fit">
        <!-- Profile picture preview -->
        <app-rounded-picture
          :picture-url="profilePictureUrl"
          picture-size="24"
          with-shadow
          class="mx-auto mb-4"
        />

        <div class="text-center">
          <form-label
            :label="t('validation.attributes.profile_picture')"
            class="mb-3 block"
          />

          <!-- Edit profile picture -->
          <label class="app-link block text-sm">
            {{ t('common.actions.edit') }}

            <input
              type="file"
              class="hidden"
              :accept="imageMimeTypes"
              @change="handleFileUpload"
            >
          </label>

          <form-error-messages
            name="image.file"
            :data-form-errors="true"
            :error-messages="imageErrors.flat()"
          />

          <!-- Delete profile picture -->
          <app-button
            v-if="profilePictureDeletable"
            color="red"
            font-size="sm"
            :label="t('common.actions.delete')"
            emphasis="low"
            @click="handleDeleteProfilePicture"
          />
        </div>

        <form-group
          name="image.id"
          type="hidden"
        />

        <form-group
          name="image._destroy"
          type="hidden"
        />

        <form-group
          name="image.file"
          :rules="imageRules"
          type="hidden"
        />

        <form-group
          name="image.name"
          :initial-value="t('validation.attributes.profile_picture')"
          type="hidden"
        />

        <form-group
          name="image.collection_name"
          initial-value="image"
          type="hidden"
        />
      </app-card-item>

      <user-avatar />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  nextTick,
  onBeforeMount,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { uniqBy, uniq } from 'lodash'
import { useField } from 'vee-validate'

import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import {
  fetchCurrencies as apiFetchCurrencies,
  updateUserBank,
} from '@shared/http/api'
import * as mapbox from '@shared/http/mapbox'
import useAppRouter from '@shared/hooks/router'
import useFormFields from '@shared/hooks/form/formFields'
import useGeoloc from '@shared/hooks/geoloc'
import useLocale from '@shared/hooks/locale'
import useResource from '@shared/hooks/resources/resource'
import AddBankAccountForm from '@extranet/components/form/bank/AddBankAccountForm.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import FormErrorMessages from '@shared/components/form/FormErrorMessages.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'
import UserAvatar from '@extranet/components/resources/user/UserAvatar.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Edit mode
  // Possible values: "profile", "currency", "locale"
  mode: {
    type: String,
    default: 'profile',
  },
})

const authStore = useAuthStore()
const flashesStore = useFlashesStore()
const { t, locale } = useI18n()
const { form } = useFormFields(props)
const appRouter = useAppRouter()
const { countries } = useGeoloc()
const { staticLocalesOptionsTranslated } = useLocale()
const user = ref(authStore.user)

// ---------- BANK ACCOUNT ----------
const formPaymentSubmitting = ref(false)
const formPaymentErrors = ref(false)

function updateBankAccount(attributes) {
  if (!formPaymentSubmitting.value) {
    formPaymentSubmitting.value = true

    const params = {
      data: {
        type: 'users',
        attributes: { ...attributes },
      },
    }

    /**
     * Push bank information in API.
     */
    updateUserBank(params)
      .then(async () => {
        flashesStore.addFlash({
          message: t('tutorials.extranet.begin.messages.payment'),
          type: 'success',
        })

        // Refresh user data.
        await authStore.getAuthenticatedUser()

        appRouter.back()
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formPaymentErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formPaymentSubmitting.value = false
      })
  }
}

// ---------- ADDRESS OPTIONS ----------

const addressOptionsFromApi = ref([])
const initialAddressSearch = ref(true)

function handleAddressSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialAddressSearch.value) {
      initialAddressSearch.value = false

      if (props.resource?.relationships?.address?.attributes?.line1?.[locale.value]) {
        // Provide an option with the resource's attribute value
        options.push({
          label: props.resource.relationships.address.attributes.line1[locale.value],
          value: props.resource.relationships.address.attributes.line1[locale.value],
        })
      }
    }

    // Add search keyword to available options
    if (searchKeywords?.length > 0) {
      options.push({
        value: searchKeywords,
        label: searchKeywords,
        icon: 'pen',
      })

      mapbox.geocoding(searchKeywords, {
        types: [
          'address',
          'poi',
        ].join(','),
        language: locale.value,
      })
        .then((r) => {
          addressOptionsFromApi.value = r.data.features
            ?.map((place) => {
              // Build option's label
              let label = ''

              // Specify address in the label when available
              if (place.place_type.includes('address') && place.address) {
                label = `${place.address} ${place.text}`
              } else if (place.place_type.includes('poi') && place.properties?.address) {
                label = `${place.text}, ${place.properties.address}`
              } else {
                label = place.text
              }

              // Adapt icon to place's type
              let icon

              if (place.place_type.includes('poi')) {
                icon = 'map-marker-alt'
              } else if (place.place_type.includes('address')) {
                icon = 'map-signs'
              }

              // Build sub label from place's context
              const subLabel = uniq(place.context?.map((c) => c.text))
                .join(', ')

              return {
                value: place.id,
                label,
                subLabel,
                icon,
                context: place.context,
                coords: {
                  latitude: place.center[1],
                  longitude: place.center[0],
                },
              }
            })
        })
        .finally(() => {
          options = options.concat(addressOptionsFromApi.value)
          resolve(uniqBy(options, 'value'))
        })
    } else {
      resolve(options)
    }
  })
}

// Sync line 1 hidden field's value with autocomplete input
function handleAutocompleteInput(event) {
  form.value.setFieldValue(
    `address.line1.${locale.value}`,
    event.target.value,
  )
}

async function handleAddressChange(value) {
  // Retrieve selected option in api options list
  const option = addressOptionsFromApi.value.find((filter) => (
    filter.value === value
  ))

  // If option retrieved
  // Use option's label as line 1 hidden field's value
  // Else, keep line 1 value
  if (option) {
    form.value.setFieldValue(
      `address.line1.${locale.value}`,
      option.label ?? null, // Force to null to avoid undefined value
    )
  }

  // Fill other form fields from mapbox context data
  // if context data is undefined, force field to null
  if (option?.context) {
    let context_dependent_locality = option.context.find(
      (context) => context.id.startsWith('locality'),
    )

    if (!context_dependent_locality) {
      context_dependent_locality = option.context.find(
        (context) => context.id.startsWith('neighborhood'),
      )
    }

    form.value.setFieldValue(
      `address.dependent_locality.${locale.value}`,
      context_dependent_locality?.text || null,
    )

    const context_city = option.context.find(
      (context) => context.id.startsWith('place'),
    )

    form.value.setFieldValue(
      `address.city.${locale.value}`,
      context_city?.text || null,
    )

    const context_zip = option.context.find(
      (context) => context.id.startsWith('postcode'),
    )

    form.value.setFieldValue(
      'address.zip',
      context_zip?.text || null,
    )

    let context_administrative_area = option.context.find(
      (context) => context.id.startsWith('region'),
    )

    if (!context_administrative_area) {
      context_administrative_area = option.context.find(
        (context) => context.id.startsWith('district'),
      )
    }

    form.value.setFieldValue(
      `address.administrative_area.${locale.value}`,
      context_administrative_area?.text || null,
    )

    const context_country = option.context.find(
      (context) => context.id.startsWith('country'),
    )

    form.value.setFieldValue(
      'address.country',
      context_country?.short_code?.toUpperCase() || null,
    )

    form.value.setFieldValue(
      `address.line2.${locale.value}`,
      null,
    )
  }

  // Reset latitude and longitude anyway
  resetCoords()

  // Wait for each address field to change, which reset coords, and at last set the new coords
  await nextTick()
  if (option?.coords) {
    form.value.setFieldValue(
      'address.latitude',
      option.coords.latitude,
    )

    form.value.setFieldValue(
      'address.longitude',
      option.coords.longitude,
    )
  }
}

function resetCoords() {
  form.value.setFieldValue(
    'address.latitude',
    null,
  )

  form.value.setFieldValue(
    'address.longitude',
    null,
  )
}

// ---------- PROFILE PICTURE ----------

const {
  errors: imageErrors,
} = useField('image.file')

const imageMimeTypes = import.meta.env.VITE_VALID_IMAGE_MIMETYPES
const imageMaxSize = import.meta.env.VITE_VALID_MAX_FILE_SIZE_KB
const imageMaxWidth = import.meta.env.VITE_VALID_MAX_IMAGE_WIDTH
const imageMaxHeight = import.meta.env.VITE_VALID_MAX_IMAGE_HEIGHT

const imageRules = [
  `mimes:${imageMimeTypes}`,
  `size.file:${imageMaxSize}`,
  `dimensions:max_width=${imageMaxWidth},max_height=${imageMaxHeight}`,
].join('|')

const { getImageUrlFromResource } = useResource()

const avatarUrl = computed(() => (
  getImageUrlFromResource(props.resource, 'sm')
))

function handleFileUpload(e) {
  // Check if a file is present,
  // useful when upload is cancel
  const targetFile = e.target.files?.[0]

  if (targetFile) {
    // Assign new picture value
    form.value.setFieldValue('image.file', targetFile)
    // Unmark picture destruction (if it was)
    form.value.setFieldValue('image._destroy', false)
  }
}

function handleDeleteProfilePicture() {
  // Un-assign picture value
  form.value.setFieldValue('image.file', null)
  // Mark picture for destruction
  form.value.setFieldValue('image._destroy', true)
}

const placeholderPath = '/images/shared/placeholders/users.png'

const profilePictureUrl = computed(() => {
  // No picture url if it is marked for destruction
  if (!form.value.values.image?._destroy) {
    // Uploaded picture
    if (form.value.values.image?.file) {
      return URL.createObjectURL(form.value.values.image.file)
    }

    // Or user's current picture
    return avatarUrl.value
  }

  return placeholderPath
})

// Is there a profile picture to delete
const profilePictureDeletable = computed(() => (
  profilePictureUrl.value && profilePictureUrl.value !== placeholderPath
))

// ---------- CURRENCY ----------

// Return currencies options used in select control,
// based on currencies resources
function formatCurrenciesOptions(currencies) {
  return currencies.map((currencyResource) => ({
    label: currencyResource.attributes.name,
    value: currencyResource.id,
  }))
}

const initialCurrenciesSearch = ref(true)

function handleCurrenciesSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialCurrenciesSearch.value) {
      initialCurrenciesSearch.value = false

      if (props.resource?.relationships?.currency) {
        // Provide an option with the resource's relationship value
        options.push(formatCurrenciesOptions([props.resource.relationships.currency])[0])
      }
    }

    apiFetchCurrencies({
      'filter[name]': searchKeywords,
    })
      .then((response) => {
        // Get the new options from the response
        options = options.concat(formatCurrenciesOptions(response.data.data))
      })
      .finally(() => {
        resolve(uniqBy(options, 'value'))
      })
  })
}

const isModeBankAccount = computed(() => (
  props.mode === 'bank_account'
))

onBeforeMount(() => {
  if (
    isModeBankAccount.value
      && !user.value.attributes.can_update_bank_account
  ) {
    appRouter.push('/')
  }
})
</script>
