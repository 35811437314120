<template>
  <div>
    <!-- Pending -->
    <div
      v-if="state === 'pending'"
      class="mb-4 flex items-center justify-between"
    >
      <p class="font-bold text-orange-500 text-sm">
        {{ t('institutions_employees.show.pending') }}
      </p>

      <app-button
        v-if="resource.authorizations.cancel"
        feature="cancel"
        emphasis="low"
        class="ml-4"
        @click="handleCanceled"
      />
    </div>

    <!-- Remove -->
    <app-button
      v-if="resource.authorizations.remove"
      feature="quit"
      emphasis="low"
      class="float-right"
      @click="handleQuit"
    />

    <app-overlay
      :show="quitConfirmModalDisplayed"
      @clicked="handleQuitCancel"
    >
      <app-modal @closed="handleQuitCancel">
        <app-confirm
          confirm-color="red"
          @cancelled="handleQuitCancel"
          @confirmed="handleQuitConfirm"
        >
          <p>
            {{
              t('institutions_employees.quit.confirm', {
                poi_name: getAttributeTranslation(pointOfInterest.attributes.title),
              })
            }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>

    <!-- Institution preview -->
    <point-of-interest-butler-show
      v-if="pointOfInterest"
      :resource="pointOfInterest"
      class="clear-both"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useSpinnerStore } from '@shared/store/spinner'
import { useFlashesStore } from '@shared/store/flashes'
import {
  cancelInstitutionsEmployees,
  removeInstitutionsEmployees,
} from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useInstitutionEmployee from '@shared/hooks/resources/institutionEmployee'
import PointOfInterestButlerShow from '@extranet/components/resources/point_of_interest/PointOfInterestButlerShow.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppModal from '@shared/components/ui/AppModal.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'canceled',
  'quitted',
])

const { t } = useI18n()
const flashesStore = useFlashesStore()
const spinnerStore = useSpinnerStore()

const { getAttributeTranslation } = useLocale()

const {
  pointOfInterest,
  state,
} = useInstitutionEmployee(props)

// ---------- CANCEL ----------

function handleCanceled() {
  spinnerStore.enable()

  cancelInstitutionsEmployees(props.resource.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('institutions_employees.cancel.success'),
        type: 'success',
      })

      emits('canceled')
    })
    .finally(() => {
      spinnerStore.disable()
    })
}

// ---------- QUIT ----------

const quitConfirmModalDisplayed = ref(false)

function handleQuit() {
  quitConfirmModalDisplayed.value = true
}

function handleQuitConfirm() {
  quitConfirmModalDisplayed.value = false
  quit()
}

function handleQuitCancel() {
  quitConfirmModalDisplayed.value = false
}

function quit() {
  spinnerStore.enable()

  removeInstitutionsEmployees(props.resource.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('institutions_employees.quit.success'),
        type: 'success',
      })

      emits('quitted')
    })
    .finally(() => {
      spinnerStore.disable()
    })
}
</script>
