<template>
  <div
    class="flex items-center
    space-y-2 md:space-y-0 md:space-x-2"
  >
    <share-action
      v-if="canShare"
      :resource="resource"
      :emphasis="'low'"
      hide-text
      :hide-icon="false"
    />

    <app-button
      v-if="resource.authorizations.update"
      feature="edit"
      emphasis="low"
      hide-text
      :hide-icon="false"
      @click="handleEdit(resource.id)"
    />

    <app-button
      v-if="resource.authorizations.delete"
      feature="delete"
      emphasis="low"
      hide-text
      :hide-icon="false"
      @click="handleDestroy"
    />

    <app-button
      feature="pdf"
      emphasis="low"
      hide-text
      :hide-icon="false"
      @click="handlePdf"
    />
  </div>

  <!-- Delete modal -->
  <app-overlay
    :show="deleteConfirmModalDisplayed"
    @clicked="handleDestroyCancel"
  >
    <app-modal @closed="handleDestroyCancel">
      <app-confirm
        @cancelled="handleDestroyCancel"
        @confirmed="handleDestroyConfirm"
      >
        <p>
          {{
            t(
              'excursions.destroy.confirm',
              { excursion_name: getAttributeTranslation(resource.attributes.title) }
            )
          }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <pdf-download-modal
    :show="loadingModalDisplayed"
    @closed="handleLoadingClose"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import useLocale from '@shared/hooks/locale'
import usePdf from '@shared/hooks/pdf'
import { pdfExcursion } from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import ShareAction from '@extranet/components/resources/excursion/actions/ShareAction.vue'
import PdfDownloadModal from '@extranet/components/features/pdf/PdfDownloadModal.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'destroyed',
])

const router = useRouter()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ---------- PERMISSIONS ----------

const canShare = computed(() => (
  props.resource.authorizations.share
))

// ---------- EDIT ----------

function handleEdit(id) {
  router.push({
    name: 'edit-excursions',
    params: { id },
  })
}

// ---------- DELETE ----------

const deleteConfirmModalDisplayed = ref(false)

function handleDestroy() {
  deleteConfirmModalDisplayed.value = true
}

function handleDestroyConfirm() {
  deleteConfirmModalDisplayed.value = false
  emits('destroyed')
  props.destroyCallback()
}

function handleDestroyCancel() {
  deleteConfirmModalDisplayed.value = false
}

// ---------- PDF ----------

const { downloadPdf } = usePdf()
const loadingModalDisplayed = ref(false)

function handlePdf() {
  // Loading modal
  handleLoadingDisplay()

  pdfExcursion(props.resource.id)
    .then((response) => {
      downloadPdf(response.data, 'excursion')
    })
    .finally(() => {
      handleLoadingClose()
    })
}

function handleLoadingDisplay() {
  loadingModalDisplayed.value = true
}

function handleLoadingClose() {
  loadingModalDisplayed.value = false
}
</script>
