<template>
  <div class="flex justify-between items-center">
    <app-heading>
      {{ t('models.support_request', 2) }}
    </app-heading>
  </div>

  <div class="w-full mt-2 flex items-center">
    <form-control-text
      class="flex-1"
      name="support_requests_search"
      :placeholder="t('support_requests.index.search')"
      :icon-props="{ library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' }"
      @changed="handleSearch"
    />
  </div>

  <div class="w-full mt-4 mb-2 flex justify-between items-center">
    <support-request-filters
      class="w-8/12"
      @filters-applied="handleFiltersUpdated"
    />
  </div>

  <!-- Support Requests card list -->
  <div
    v-if="!loading"
    class="pb-12"
  >
    <template
      v-if="supportRequests.length > 0"
    >
      <app-card-item
        v-for="(request, i) in supportRequests"
        :key="i"
        :component="SupportRequestCard"
        class="overflow-hidden my-4"
      >
        <support-request-card
          v-bind="request"
        />
      </app-card-item>
    </template>
    <p
      v-else
      class="mt-2 text-center"
    >
      {{ t('support_requests.index.no_results') }}
    </p>

    <template v-if="lastPage && page < lastPage">
      <a
        v-if="nextPageFetching === false"
        href="#"
        class="block text-center app-link w-full"
        @click.prevent="nextPage"
      >
        {{ t('common.actions.load_more') }}
      </a>
    </template>

    <div
      v-if="nextPageFetching"
      class="text-center w-full"
    >
      <app-spinner
        class="text-theme-500"
        size="lg"
      />
    </div>
  </div>

  <template v-else>
    <support-request-card-empty
      v-for="i in 7"
      :key="i"
      class="my-4"
    />
  </template>
  <div class="flex justify-center fixed right-0 left-0 bottom-24">
    <app-button
      v-if="authStore.user.authorizations.support_requests.create"
      feature="create"
      :label="t('support_requests.show.add_request')"
      class="w-5/6"
      @click="router.push({ name: 'create-support-requests' })"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useAuthStore } from '@shared/store/auth'
import { useFiltersStore } from '@shared/store/filters'
import { fetchSupportRequests as fetchApiSupportRequests } from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import SupportRequestCard from '@shared/components/resources/support_request/SupportRequestCard.vue'
import SupportRequestCardEmpty from '@shared/components/resources/support_request/SupportRequestCardEmpty.vue'
import SupportRequestFilters from '@app/components/resources/support_request/SupportRequestFilters.vue'

const props = defineProps({})
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()
const filtersStore = useFiltersStore()

const page = ref(1)
const typingTimer = ref(0)
const searchKeywords = ref(null)
const nextPageFetching = ref(false)
const supportRequests = ref([])
const loading = ref(true)
const lastPage = ref(null)

// ---------- SUPPORT REQUESTS ----------

function fetchSupportRequests(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'filters': allFieldsToSubmit.value,
  }

  fetchApiSupportRequests(params)
    .then((response) => {
      const oldSupportRequests = supportRequests.value
      const newSupportRequests = response.data.data?.map((supportRequest) => ({
        resource: supportRequest,
      })) ?? []
      if (nextPage) {
        // add new results after the old ones
        supportRequests.value = [...oldSupportRequests, ...newSupportRequests]
      } else {
        // replace the old results with the new ones
        supportRequests.value = newSupportRequests
      }

      lastPage.value = response.data.meta?.last_page
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

function resetPage() {
  page.value = 1
}

function nextPage() {
  page.value += 1
  fetchSupportRequests(true)
}

// ---------- SEARCH ----------

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  typingTimer.value = setTimeout(() => {
    // Reset the pagination
    page.value = 1
    searchKeywords.value = value
    fetchSupportRequests()
  }, 400)
}

// ---------- FILTERS ----------

function handleFiltersUpdated() {
  resetPage()
  fetchSupportRequests()
}

const {
  allFieldsToSubmit,
} = useForm(props, {
  initialValues: filtersStore.filters.supportRequests,
  formName: 'supportRequestsIndexFiltersForm',
})

// ---------- LIFECYCLES ----------
onMounted(() => {
  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  fetchSupportRequests()
})
</script>
