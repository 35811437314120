<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <user-public-profile-resource
    v-if="user && !spinnerStore.active"
    :resource="user"
    @contact-updated="handleContactUpdated"
  />
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { useSpinnerStore } from '@shared/store/spinner'
import { fetchUser as fetchApiUser } from '@shared/http/api'
import { displayName as displayNameHelper } from '@shared/helpers/user'
import breadcrumbUser from '@extranet/breadcrumbs/user'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import UserPublicProfileResource from '@extranet/components/resources/user/UserPublicProfileResource.vue'

const route = useRoute()
const spinnerStore = useSpinnerStore()

// ---------- USER ----------

const user = ref(null)

function fetchUser() {
  const { id } = route.params
  spinnerStore.enable()

  fetchApiUser(id)
    .then((response) => {
      user.value = response.data.data
    })
    .finally(() => {
      spinnerStore.disable()
    })
}

function handleContactUpdated() {
  fetchUser()
}

const displayName = computed(() => (
  displayNameHelper(user.value)
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchUser()
})

// ---------- BREADCRUMBS ----------

const { show } = breadcrumbUser()

const breadcrumbs = computed(() => (
  show({
    id: user.value?.id,
    label: displayName.value,
  })
))
</script>
