import { reactive } from 'vue'
import { defineStore } from 'pinia'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useResourcesStore = defineStore('resources', () => {
  const resources = reactive({
    pointsOfInterest: [],
  })

  function find(payload) {
    const type = payload.type

    return resources[type]?.find((resource) => resource.id === payload.id)
  }

  function push(payload) {
    // Which state's key will be searched in
    const type = payload.type
    const newResources = payload.resources

    newResources?.forEach((newResource) => {
      // Check if it is already in store
      const oldResource = resources[type]?.find((resource) => resource.id === payload.id)

      if (oldResource) {
        oldResource.attributes = {
          ...oldResource.attributes,
          ...newResource.attributes,
        }

        oldResource.relationships = {
          ...oldResource.relationships,
          ...newResource.relationships,
        }
      } else {
        // Add the new resource
        resources[type].push(newResource)
      }
    })
  }

  return {
    resources,
    find,
    push,
  }
})
