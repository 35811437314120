import { computed, ref } from 'vue'

import { useAuthStore } from '@shared/store/auth'

export default function useSupportRequest(props, emits) {
  const authStore = useAuthStore()

  // ---------- PERMISSIONS ----------

  const canAddMessage = computed(() => (
    props.resource.authorizations.addMessage
      && authStore.user.authorizations.support_messages.create
  ))

  // ---------- MESSAGES ----------

  const messages = computed(() => (
    props.resource.relationships.messages
  ))

  const showedMessages = computed(() => (
    messages?.value.filter((m, i) => (
      i !== 0
    ))
  ))

  const firstMessage = computed(() => (
    messages?.value[0]
  ))

  const userMessages = computed(() => (
    messages?.value.filter((message) => (
      message.relationships.user.id === authStore.user.id
    ))
  ))

  // ---------- IMAGES ----------

  const gallery = computed(() => (
    props.resource.relationships?.images
  ))

  // ---------- CANCEL MODAL ----------

  const cancelConfirmModalDisplayed = ref(false)

  function handleCancel() {
    cancelConfirmModalDisplayed.value = true
  }

  function handleCancelConfirm() {
    cancelConfirmModalDisplayed.value = false
    emits('canceled')
    props.cancelCallback()
  }

  function handleCancelCancel() {
    cancelConfirmModalDisplayed.value = false
  }

  // ---------- STYLE ----------

  /**
   * Design for state badge
   *
   * @param state
   *
   * @returns {string}
   */
  function stateDesign(state) {
    switch (state) {
      case 'pending-user':
        return 'warning'
      case 'pending-support':
        return 'warning'
      case 'resolved':
        return 'success'
      case 'archived':
        return 'default'
      case 'canceled':
        return 'default'
    }
  }

  return {
    gallery,
    userMessages,
    canAddMessage,
    firstMessage,
    messages,
    showedMessages,
    cancelConfirmModalDisplayed,
    handleCancel,
    handleCancelConfirm,
    handleCancelCancel,
    stateDesign,
  }
}
