<template>
  <form
    @submit.prevent="handleFiltersApplied"
  >
    <div>
      <h3 class="text-lg font-bold mb-4">
        {{ capitalize(t('form.labels.geoloc_setting.distance_filter')) }}
      </h3>

      <form-group
        name="geoloc"
        :label="t('form.labels.points_of_interest_filters.geoloc')"
        margins="mb-0"
        type="select"
        :submittable="false"
        :form-control-props="{
          clearable: true,
          filterable: false,
          options: handleGeolocFilterSearch,
          minChars: 3,
          searchHeaderText: t('form.hints.geoloc_setting.filter_header'),
        }"
        @changed="handleGeolocFilterChange"
      >
        <template #control-vue-multiselect-option="option">
          <div>
            <template v-if="option.icon">
              <font-awesome-icon :icon="option.icon" />
              &nbsp;
            </template>

            <strong>
              {{ option.label }}
            </strong>

            <template v-if="option.subLabel">
              <br>
              <em class="text-sm">
                {{ option.subLabel }}
              </em>
            </template>
          </div>
        </template>
      </form-group>

      <form-group
        name="latitude"
        type="hidden"
        @changed="(value) => handleFilterChange('latitude', value)"
      />

      <form-group
        name="longitude"
        type="hidden"
        @changed="(value) => handleFilterChange('longitude', value)"
      />

      <form-group
        v-if="geolocFilterPresent"
        name="limit_to_a_radius"
        :submittable="false"
        :label="t('form.labels.geoloc_setting.limit_to_a_radius')"
        :hint="t('form.hints.points_of_interest_filters.radius')"
        type="switch"
        @changed="(value) => handleFilterChange('limit_to_a_radius', value)"
      />

      <form-group
        v-else
        :initial-value="false"
        name="limit_to_a_radius"
        :submittable="false"
        type="hidden"
        @changed="(value) => handleFilterChange('limit_to_a_radius', value)"
      />

      <form-group
        v-if="limitToRadiusValue"
        class="top-3 relative"
        name="radius"
        :label="false"
        :initial-value="filtersStore.filters.pointsOfInterest.radius || 10"
        type="slider"
        rules="min.numeric:1|required"
        :form-control-props="{
          min: 1,
          max: maxRadius,
          formatSliderTooltips: formatSliderTooltips,
        }"
        @changed="(value) => handleFilterChange('radius', value)"
      />

      <form-group
        v-else
        name="radius"
        :initial-value="null"
        type="hidden"
        @changed="(value) => handleFilterChange('radius', value)"
      />

      <form-group
        v-if="limitToRadiusValue"
        name="measurement_system"
        :label="t('form.labels.geoloc_setting.measure_unit')"
        type="radio"
        :form-control-props="{
          options: measureUnitOptions,
        }"
        @changed="(value) => handleFilterChange('measurement_system', value)"
      />

      <h3 class="text-lg font-bold mb-4 mt-8">
        {{ capitalize(t('form.labels.geoloc_setting.other_filters')) }}
      </h3>

      <form-group
        name="point_of_interest_category_id"
        type="select"
        margins="mb-0"
        :form-control-props="{
          clearable: true,
          filterable: false,
          options: handleCategoriesSearch,
        }"
        @changed="handleCategoryFilterChange"
      />

      <form-group
        ref="selectTags"
        name="tags_ids"
        type="select"
        margins="mb-0"
        :form-control-props="{
          filterable: false,
          mode: 'tags',
          options: handleTagsSearch,
        }"
        @changed="(value) => handleFilterChange('tags_ids', value)"
      />

      <form-group
        name="zip"
        margins="mb-0"
        @changed="(value) => handleFilterChange('zip', value)"
      />

      <form-group
        name="city"
        margins="mb-0"
        @changed="(value) => handleFilterChange('city', value)"
      />

      <form-group
        name="countries"
        type="select"
        margins="mb-0"
        :form-control-props="{
          mode: 'tags',
          options: countriesOptions,
        }"
        @changed="(value) => handleFilterChange('countries', value)"
      >
        <template #control-vue-multiselect-option="option">
          <span :class="`flag:${option.value}`" />
          &nbsp;
          {{ option.label }}
        </template>

        <template #control-vue-multiselect-singlelabel="option">
          <span :class="`flag:${option.value}`" />
          &nbsp;
          {{ option.label }}
        </template>
      </form-group>
    </div>

    <div class="flex justify-center mt-4">
      <app-button
        feature="reset"
        :label="t('common.actions.reset')"
        class="mr-5"
        padding="py-2 px-6"
        emphasis="low"
        font-color-classes="text-theme-900"
        @click="handleResetFilters"
      />

      <app-button
        type="submit"
        feature="apply"
        :label="t('common.actions.apply')"
        padding="py-2 px-6"
      />
    </div>
  </form>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isNil, capitalize } from 'lodash'

import { useFiltersStore } from '@shared/store/filters'
import useGeoloc from '@shared/hooks/geoloc'
import useGeolocFilters from '@extranet/hooks/geolocFilters'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import useCategories from '@extranet/hooks/categories'
import useTags from '@extranet/hooks/tags'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import useFilters from '@shared/hooks/filters'

const emits = defineEmits([
  'filters-applied',
])

const { t } = useI18n()
const filtersStore = useFiltersStore()

const { countries: countriesOptions } = useGeoloc()

// ---------- CATEGORIES ----------

const {
  handleCategoriesSearch,
} = useCategories()

// ---------- TAGS ----------

const {
  handleTagsSearch,
} = useTags()

const selectTags = ref()

// ---------- FILTERS ----------

const form = inject('pointsOfInterestIndexFiltersForm')

const {
  maxRadius,
  handleGeolocFilterChange: handleGeolocFilterChangeHook,
  handleGeolocFilterSearch,
  formatSliderTooltips,
} = useGeolocFilters(form.value)

const {
  handleResetFilters,
  handleFiltersApplied,
  handleFilterChange,
} = useFilters('pointsOfInterest', form.value, { emits })

const {
  measureUnitOptions,
} = useSelectOptions()

const limitToRadiusValue = computed(() => (
  form.value.values.limit_to_a_radius
))

const geolocFilterPresent = computed(() => (
  !isNil(form.value.values.geoloc)
))

function handleGeolocFilterChange(value) {
  handleFilterChange('geoloc', value)
  handleGeolocFilterChangeHook(value)
}

function handleCategoryFilterChange(value) {
  handleFilterChange('point_of_interest_category_id', value)

  // Reset tags' filter, because it depends on selected category
  form.value.setFieldValue('tags_ids', [])

  // Refresh tags' options, to let it adapt to the selected category
  selectTags.value.$refs.formControlRef.$refs.multiselect.refreshOptions()
}
</script>
