<template>
  <div>
    <p
      class="text-center font-bold mt-6"
    >
      {{ t('auth.extranet_register.form.step_title') }} {{ currentStep }} : {{ formTitle }}
    </p>

    <div
      class="mt-8"
    >
      <div class="flex flex-col md:flex-row">
        <form-group
          :rules="requiredIfEmployee"
          name="employee.last_name"
          :label="t('validation.attributes.last_name')"
          type="text"
          class="flex-1 mr-0 md:mr-2"
        />

        <form-group
          :rules="requiredIfEmployee"
          name="employee.first_name"
          :label="t('validation.attributes.first_name')"
          type="text"
          class="flex-1"
        />
      </div>

      <form-group
        :rules="`${requiredIfEmployee}|email`"
        name="employee.email"
        type="email"
        :hint="t(`form.hints.auth.register.employee.not_duplicate_email`) + t(`enums.UserRoleEnum.${enumAttribute(currentContext?.roles?.manager ?? 'DEFAULT')}`)"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <!-- Password confirmation -->
      <form-group
        :rules="`${requiredIfEmployee}|confirmed:@employee.password`"
        name="employee.password"
        type="password"
        hide-errors
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      >
        <template #hint>
          <form-password-hint-list
            :password="form.values.employee?.password"
            :external-items="[
              {
                validated: checkPasswordNotMatch,
                text: t('validation.multiple_password_different', {
                  manager_name: t(`enums.UserRoleEnum.${enumAttribute(currentContext?.roles?.manager ?? 'DEFAULT')}`),
                }),
              }
            ]"
          />
        </template>
      </form-group>

      <!-- Password confirmation -->
      <form-group
        :rules="`${requiredIfEmployee}|confirmed:@employee.password`"
        name="employee.password_confirmation"
        :hint="t(`form.hints.auth.register.employee.not_duplicate_password`) + t(`enums.UserRoleEnum.${enumAttribute(currentContext?.roles?.manager ?? 'DEFAULT')}`)"
        type="password"
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      />
    </div>
    <div
      class="flex justify-between"
    >
      <app-button
        type="button"
        display-classes="flex"
        feature="back"
        class="py-3 px-6 min-w-fit max-w-8/10 mt-8 gap-2"
        :label="t('common.actions.return')"
        :hide-icon="false"
        @click="back"
      />

      <app-button
        type="button"
        :disabled="!isFormFilled"
        display-classes="flex"
        feature="next"
        class="py-3 px-6 min-w-fit max-w-8/10 mt-8 flex-row-reverse gap-2"
        :label="t('common.actions.next')"
        :hide-icon="false"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useEnum from '@shared/hooks/enum'
import useTranslation from '@shared/hooks/translation'
import useFormFields from '@shared/hooks/form/formFields'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'

const emits = defineEmits([
  'next',
  'back',
])

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Get the current step at the time.
  currentStep: {
    type: Number,
    required: true,
  },
  // Get current context
  currentContext: {
    type: Object,
    required: true,
  },
})

const { enumAttribute } = useTranslation()
const { fromPHPEnum } = useEnum()
const { t } = useI18n()
const { form } = useFormFields(props)

const isFormFilled = computed(() => (
  form.value.values.employee?.last_name
    && form.value.values.employee?.first_name
    && form.value.values.employee?.email
    && form.value.values.employee?.password
    && form.value.values.employee?.password_confirmation
    && checkPasswordNotMatch.value
    && Object.keys(form.value.errors).length === 0
))

// ---------- COMPUTED ----------

// Check if manager & employee passwords are different or not
const checkPasswordNotMatch = computed(() => (
  ((form.value.values?.employee?.password?.length > 0) && (form.value.values?.employee?.password_confirmation?.length > 0))
  && (form.value.values?.employee?.password !== form.value.values?.password)
))

const requiredIfEmployee = computed(() => (
  props.currentContext?.has_employee_account ? 'required' : ''
))

const formTitle = computed(() => (`${t('auth.extranet_register.form.form_title')} "${t(`enums.UserRoleEnum.${fromPHPEnum(props.currentContext?.roles?.employee)}`)}"`))

// ---------- STEPS ----------

function next() {
  emits('next')
}

function back() {
  emits('back')
}
</script>
