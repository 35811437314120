<template>
  <app-overlay
    show
    @clicked="handleModalClose"
  >
    <app-modal
      max-width-classes="max-w-screen-2xl"
      text-align-classes="text-left"
      :loading="userLoading"
      class="h-screen"
      @closed="handleModalClose"
    >
      <user-public-profile-resource
        v-if="userResource"
        :resource="userResource"
        :with-actions="false"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref, onMounted } from 'vue'

import { useModalsStore } from '@shared/store/modals'
import { fetchUser as apiFetchUser } from '@shared/http/api'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import UserPublicProfileResource from '@extranet/components/resources/user/UserPublicProfileResource.vue'

const userResource = ref()
const userDisplayed = ref(false)
const userLoading = ref(false)
const modalsStore = useModalsStore()

function handleModalClose() {
  modalsStore.reset('user_show')
}

function fetchUser() {
  const id = modalsStore.modals.user_show.id

  userDisplayed.value = true
  userLoading.value = true

  apiFetchUser(id)
    .then((response) => {
      userResource.value = response.data.data
    })
    .finally(() => {
      userLoading.value = false
    })
}

// ---------- LIFECYCLE ----------

onMounted(() => {
  fetchUser()
})
</script>
