<template>
  <component
    :is="withModal ? 'div' : 'router-link'"
    :to="withModal ? null : { name: 'show-points-of-interest', params: { id: resource.id } }"
    class="flex items-center cursor-pointer"
    :title="l10nTitle"
    @click="handleClick"
  >
    <div class="mr-2">
      <app-rounded-picture
        :picture-url="getMainImageUrl('xs')"
        picture-size="8"
        icon-name="map-marker-alt"
        icon-size="xs"
      />
    </div>

    <span class="truncate">
      {{ l10nTitle }}
    </span>

    <span
      v-if="resource.attributes.certified"
      class="ml-2"
    >
      <app-icon
        library="coolicon"
        name="circle_check_outline"
      />
    </span>
  </component>
</template>

<script setup>
import { useModalsStore } from '@shared/store/modals'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display POI in a modal or not
  withModal: {
    type: Boolean,
    default: false,
  },
  // Function to execute
  // on POI click
  onClick: {
    type: Function,
    default: null,
  },
})

const modalsStore = useModalsStore()

const {
  getMainImageUrl,
  l10nTitle,
} = usePointOfInterest(props)

/**
 * Use either props function
 * or default one
 */
function handleClick() {
  if (props.onClick) {
    props.onClick()
  } else {
    show()
  }
}

function show() {
  if (props.withModal) {
    modalsStore.setProperties({
      name: 'point_of_interest_show',
      display: true,
      id: props.resource.id,
    })
  }
}
</script>
