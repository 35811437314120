<template>
  <header
    class="sticky top-0 right-0 w-full z-20 bg-body h-16 px-8 text-theme-500 flex justify-between items-center leading-none"
  >
    <!-- Left side -->
    <div>
      <!-- Burger menu -->
      <app-icon
        library="coolicon"
        name="menu_alt_03"
        size="text-4xl"
        class="cursor-pointer lg:hidden"
        @click="openMobileNavbar"
      />
    </div>

    <!-- Subscription end -->
    <div
      v-if="stillInFreePeriod"
    >
      {{ t('plans.free_period_ended_in', { in: currentSubscription.attributes.free_period_ended_in }) }}
      &bull;
      <router-link
        :to="{ name: 'subscriptions' }"
        class="app-link"
      >
        {{ t('users.profile.manage_my_subscription') }}
      </router-link>
    </div>

    <!-- Right side -->
    <div class="flex items-center">
      <!-- Notifications -->
      <the-notifications />

      <!-- User options -->
      <app-rounded-picture
        id="userDropdown"
        :picture-url="avatarUrl"
        :icon-name="userIcon"
        picture-size="10"
        icon-size="sm"
        with-shadow
        class="cursor-pointer ml-4"
        @click="toggleUserDropdown"
      />
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useNavbarStore } from '@extranet/store/navbar'
import { useDropdownStore } from '@extranet/store/dropdown'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import TheNotifications from '@shared/components/features/notifications/TheNotifications.vue'
import useResource from '@shared/hooks/resources/resource'
import useAuthUser from '@shared/hooks/auth/authUser'

const { t } = useI18n()
const navbarStore = useNavbarStore()
const authStore = useAuthStore()
const dropdownStore = useDropdownStore()

const {
  getIconFromResourceType,
  getImageUrlFromResource,
  USER_TYPE,
} = useResource()

const {
  currentSubscription,
  stillInFreePeriod,
} = useAuthUser()

// ---------- MOBILE NAVBAR ----------

function openMobileNavbar() {
  // Open navbar
  navbarStore.setState(true)
}

// ---------- USER ----------

const user = computed(() => (
  authStore.user
))

const userIcon = getIconFromResourceType(USER_TYPE)

const avatarUrl = computed(() => (
  getImageUrlFromResource(user.value, 'xxs')
))

// ---------- USER DROPDOWN ----------

function toggleUserDropdown() {
  if (dropdownStore.open) {
    dropdownStore.setState(false)
    return
  }

  dropdownStore.setState(true)
}
</script>
