<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <interaction-table
    mode="manager"
    :filter-id="userId"
  />
</template>

<script setup>
import { computed } from 'vue'

import { useFiltersStore } from '@shared/store/filters'
import breadcrumbInteraction from '@extranet/breadcrumbs/interaction'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import InteractionTable from '@extranet/components/resources/interaction/InteractionTable.vue'

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbInteraction()

const breadcrumbs = computed(() => (
  index()
))

// ---------- USER ID ----------

const filtersStore = useFiltersStore()

const userId = computed(() => (
  filtersStore.filters.interactions.user_id
))
</script>
