<template>
  <form-group
    v-if="!guestTravellerAccess"
    name="points_of_interest_search"
    :label="false"
    :form-control-props="{
      placeholder: t('points_of_interest.index.search'),
      iconProps: { library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' },
    }"
    @changed="handleSearch"
  />

  <point-of-interest-filters
    v-if="!guestTravellerAccess"
    class="mt-4 mb-2"
    @geoloc-updated="handleGeolocUpdated"
    @filters-applied="handleFiltersApplied"
  />

  <!-- Categories -->
  <app-slidable-list
    v-if="!guestTravellerAccess"
    ref="categoriesSlidableListRef"
    :title="capitalize(t('validation.attributes.categories'))"
    :list="categoriesSlidableList"
    :loading="categoriesLoading"
    :component="PointOfInterestCategoryCard"
    :empty-component="PointOfInterestCategoryCardEmpty"
    item-min-width="1/4"
    @item-clicked="handleCategoryClick"
  />

  <!-- Points of interest card list -->
  <div
    class="flex justify-between items-center"
  >
    <!-- Title -->
    <app-sub-heading>
      {{ t('models.point_of_interest', 2) }}
    </app-sub-heading>

    <!-- Refresh button -->
    <app-icon
      v-if="refreshGeolocButtonDisplayed"
      library="coolicon"
      name="refresh"
      size="text-2xl"
      :classes="refreshGeolocDelayed ? ['text-gray-500'] : ['text-theme-500']"
      @click="handleRefreshGeoloc"
    />
  </div>

  <call-to-register
    v-if="guestTravellerAccess"
    class="mt-6"
  />

  <div
    v-if="!guestTravellerAccess"
  >
    <!-- Results -->
    <template v-if="!loading">
      <template
        v-if="pointsOfInterest?.length > 0"
      >
        <app-card-item
          v-for="(pointOfInterest, i) in pointsOfInterest"
          :key="i"
          class="my-4 overflow-hidden"
        >
          <point-of-interest-horizontal-card
            :resource="pointOfInterest"
          />
        </app-card-item>
      </template>

      <p
        v-else
        class="mt-10 text-center border-1 border-gray-300 p-4 rounded-lg text-gray-500 shadow-lg shadow-black-200"
      >
        {{ t('points_of_interest.index.no_results_here') }}
        <br>
        {{ t('points_of_interest.index.try_more_filters') }}
      </p>

      <a
        v-if="nextPageAvailable && !nextPageFetching"
        href="#"
        class="block text-center app-link w-full"
        @click.prevent="nextPage"
      >
        {{ t('common.actions.load_more') }}
      </a>

      <div
        v-if="nextPageFetching"
        class="text-center w-full"
      >
        <app-spinner
          class="text-theme-500"
          size="lg"
        />
      </div>
    </template>

    <template v-else>
      <point-of-interest-horizontal-card-empty
        v-for="i in 5"
        :key="i"
        class="my-4"
      />
    </template>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize, orderBy, isNil } from 'lodash'
import humanizeDuration from 'humanize-duration'

import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import { useFiltersStore } from '@shared/store/filters'
import {
  fetchPointsOfInterest,
  fetchPointOfInterestCategories as apiFetchPointOfInterestCategories,
} from '@shared/http/api'
import useAccess from '@extranet/hooks/access'
import useForm from '@shared/hooks/form/form'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppSlidableList from '@app/components/ui/AppSlidableList.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import AppSubHeading from '@app/components/ui/AppSubHeading.vue'
import CallToRegister from '@app/components/resources/guest/CallToRegister.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import PointOfInterestCategoryCard from '@app/components/resources/point_of_interest_category/PointOfInterestCategoryCard.vue'
import PointOfInterestCategoryCardEmpty from '@app/components/resources/point_of_interest_category/PointOfInterestCategoryCardEmpty.vue'
import PointOfInterestFilters from '@app/components/resources/point_of_interest/PointOfInterestFilters.vue'
import PointOfInterestHorizontalCardEmpty from '@app/components/resources/point_of_interest/PointOfInterestHorizontalCardEmpty.vue'
import PointOfInterestHorizontalCard from '@app/components/resources/point_of_interest/PointOfInterestHorizontalCard.vue'

const props = defineProps({})

const { t, locale } = useI18n()
const filtersStore = useFiltersStore()
const flashesStore = useFlashesStore()
const authStore = useAuthStore()

const nextPageFetching = ref(false)
const nextPageAvailable = ref(false)
const pointsOfInterest = ref([])
const page = ref(1)
const loading = ref(false)
const { guestTravellerAccess } = useAccess()

const {
  allFieldsToSubmit,
  form,
} = useForm(props, {
  initialValues: filtersStore.filters.pointsOfInterest,
  formName: 'pointsOfInterestIndexFiltersForm',
})

function fetchPois(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  // Build params
  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'mode': 'index',
    'filters': allFieldsToSubmit.value,
    'order': allFieldsToSubmit.value.order,
    'source': 'app',
    'use_geoloc_setting': true,
  }

  fetchPointsOfInterest(params)
    .then((response) => {
      const oldPointsOfInterest = pointsOfInterest.value
      const newPointsOfInterest = response.data.data

      if (nextPage) {
        // add new results after the old ones
        pointsOfInterest.value = [...oldPointsOfInterest, ...newPointsOfInterest]
      } else {
        // replace the old results with the new ones
        pointsOfInterest.value = newPointsOfInterest
      }

      // Check if navigation available
      nextPageAvailable.value = !isNil(response.data.links?.next)
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

function resetPage() {
  page.value = 1
}

function nextPage() {
  page.value += 1

  if (!guestTravellerAccess.value) {
    fetchPois(true)
  }
}

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    if (!guestTravellerAccess.value) {
      fetchPois()
    }
  }, 400)
}

async function handleGeolocUpdated() {
  resetPage()
  if (!guestTravellerAccess.value) {
    fetchPois()
  }
}

// ---------- FILTERS ----------

function handleFiltersApplied() {
  resetPage()
  if (!guestTravellerAccess.value) {
    fetchPois()
  }
}

// ---------- CATEGORIES ----------

const categories = ref([])
const categoriesLoading = ref(false)
const categoriesSlidableListRef = ref() // Template ref

// Retrieve selected category from store
const selectedCategoryId = computed(() => filtersStore.filters.pointsOfInterest.point_of_interest_category_id)

function fetchCategories() {
  categoriesLoading.value = true

  apiFetchPointOfInterestCategories()
    .then((response) => {
      const oldCategories = categories.value
      const newCategories = response.data.data ?? []

      // Add new results after the old ones
      categories.value = [...oldCategories, ...newCategories]
    })
    .finally(() => {
      categoriesLoading.value = false
    })
}

function handleCategoryClick(newSelectedCategory) {
  const oldSelectedCategoryId = selectedCategoryId.value
  const newSelectedCategoryId = newSelectedCategory.resource.id
  let newFilterValue = null

  // If this category is already selected
  if (newSelectedCategoryId === oldSelectedCategoryId) {
    // Unselect this category
    newFilterValue = null
  } else {
    // Select this category
    newFilterValue = newSelectedCategoryId
  }

  form.setFieldValue('point_of_interest_category_id', newFilterValue)

  // Scroll back to list's start
  categoriesSlidableListRef.value.$refs.slidable.scrollTo({ left: 0, behavior: 'smooth' })

  handleFiltersApplied()
}

// Add the disabled attribute to categories
const categoriesWithDisabledStatus = computed(() => (
  categories.value.map((category) => ({
    ...category,
    attributes: {
      ...category.attributes,
      // Disabled if there is a selection, and not part of it
      disabled: (
        selectedCategoryId.value
        && selectedCategoryId.value !== category.id
      ),
    },
  }))
))

// Let the enabled category at first
const orderedCategories = computed(() => (
  orderBy(categoriesWithDisabledStatus.value, ['attributes.disabled'], ['asc'])
))
// TODO: Une computed est imuable!
// Slidable list prop
const categoriesSlidableList = computed(() => (
  // Wrap each category in a "resource" attribute
  orderedCategories.value.map((category) => ({
    resource: category,
  }))
))

// ---------- REFRESH GEOLOC ----------

const refreshDelayGeoloc = ref(0) // Delay in milliseconds
const refreshDelayGeolocInterval = ref()

async function handleRefreshGeoloc() {
  if (!refreshGeolocDelayed.value) {
    refreshGeoloc()
  } else {
    // Inform user about the delay
    flashesStore.addFlash({
      message: t(
        'points_of_interest.index.refresh_delayed',
        {
          time_remaining: humanizeDuration(refreshDelayGeoloc.value, { language: locale.value }),
        },
      ),
      type: 'success',
    })
  }
}

async function refreshGeoloc() {
  loading.value = true

  authStore.refreshGeoloc(refreshGeoloc)
    .then(() => {
      resetPage()
      fetchPois()
      delayRefreshGeoloc()
    })
    .catch(() => {
      // Cancel loading if geoloc failed
      loading.value = false
    })
}

function delayRefreshGeoloc() {
  // Delay the next available refresh to 5 minutes
  refreshDelayGeoloc.value = 300_000

  refreshDelayGeolocInterval.value = setInterval(() => {
    refreshDelayGeoloc.value -= 1000
    if (refreshDelayGeoloc.value <= 0) {
      clearInterval(refreshDelayGeolocInterval)
    }
  }, 1000)
}

const refreshGeolocDelayed = computed(() => (
  refreshDelayGeoloc.value > 0
))

const refreshGeolocButtonDisplayed = computed(() => (
  !loading.value
    && geolocSetToAroundMe.value
))

const geolocSetToAroundMe = computed(() => (
  authStore.user?.relationships?.geoloc_setting?.attributes?.kind === 'around_me'
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchCategories()

  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  if (!guestTravellerAccess.value) {
    fetchPois()
  }
})

onUnmounted(() => {
  filtersStore.resetFilters('pointsOfInterest')
})
</script>
