<template>
  <form
    id="excursion-form"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors class="mb-3" />

    <fieldset
      :disabled="submitting"
    >
      <!-- Sticky submit button when form is not in a modal -->
      <teleport-wrapper
        v-if="step === 3 && !displayFormModal"
        to="#header-scrolling-replacement"
      >
        <app-button
          form="excursion-form"
          feature="save"
          type="submit"
          :disabled="submitting || invalid"
          :loading="submitting"
          class="float-right"
        />
      </teleport-wrapper>

      <!-- Sticky submit button when form is in a modal -->
      <div
        v-else-if="step === 3 && displayFormModal"
        class="sticky float-right top-0 px-8 py-4 z-10"
      >
        <app-button
          form="excursion-form"
          feature="save"
          type="submit"
          :disabled="submitting || invalid"
          :loading="submitting"
        />
      </div>

      <form-translatable-hint
        v-if="step === 1 || step === 3"
        class="mb-4"
      />

      <app-card-item
        v-if="!displayFormModal"
        class="p-4 mb-8"
      >
        <step-init
          v-if="step === 1 || step === 3"
          :resource="resource"
        />
      </app-card-item>

      <template v-else>
        <step-init
          v-if="step === 1 || step === 3"
          :resource="resource"
        />
      </template>

      <step-mode
        v-if="step === 2"
        :resource="resource"
        @mode-selected="(mode) => emits('modeSelected', mode)"
      />

      <step-columns
        v-if="step === 3"
        :resource="resource"
        :force-mode="forceMode"
      />

      <app-button
        v-if="step === 1 || step === 3"
        feature="save"
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        :display-classes="step === 1 ? 'flex' : 'inline-flex'"
        :class="step === 1 ? 'mx-auto' : 'float-right mt-16'"
      />
    </fieldset>
  </form>
</template>

<script setup>
import {
  ref,
  watch,
  toRefs,
  computed,
} from 'vue'

import { useModalsStore } from '@shared/store/modals'
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormTranslatableHint from '@extranet/components/form/FormTranslatableHint.vue'
import StepColumns from '@extranet/components/resources/excursion/main_form/steps/StepColumns.vue'
import StepInit from '@extranet/components/resources/excursion/main_form/steps/StepInit.vue'
import StepMode from '@extranet/components/resources/excursion/main_form/steps/StepMode.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Form's step
  forceStep: {
    type: Number,
    default: 1,
  },
  // POI's drag and drop mode
  forceMode: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'submitted',
  'modeSelected',
])

const modalsStore = useModalsStore()

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })

// Excursion's step :
// 1 : Details (title, kind)
// 2 : Mode selector
// 3 : Details + switch mode + points of interest selection
const step = ref(props.forceStep) // Initial step set from props

// Force step to change when prop is updated
const { forceStep } = toRefs(props)

watch(forceStep, (newStep) => {
  step.value = newStep
})

const displayFormModal = computed(() => (
  modalsStore.modals.excursion_form.display
))
</script>
