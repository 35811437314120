<template>
  <transition
    appear
    name="fade"
  >
    <div
      v-if="show"
      class="z-60 text-theme-500 h-full w-full flex flex-col items-center justify-center fixed top-0 left-0"
    >
      <app-spinner size="3x" />
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue'

import { useSpinnerStore } from '@shared/store/spinner'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'

const spinnerStore = useSpinnerStore()

const show = computed(() => (
  spinnerStore.active
))
</script>
