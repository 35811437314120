<template>
  <teleport-wrapper
    v-if="pointOfInterest && !spinnerStore.active"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <template v-if="pointOfInterest && !spinnerStore.active">
    <app-card-item class="p-4 max-w-screen-md">
      <claim-form
        :resource="pointOfInterest"
        :additional-errors="formErrors"
        :submitting="formSubmitting"
        @submitted="handleSubmit"
      />
    </app-card-item>
  </template>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useSpinnerStore } from '@shared/store/spinner'
import { useFlashesStore } from '@shared/store/flashes'
import {
  claimPointOfInterest,
  fetchPointOfInterest,
} from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useAppRouter from '@shared/hooks/router'
import breadcrumbPointOfInterest from '@extranet/breadcrumbs/pointOfInterest'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import ClaimForm from '@extranet/components/features/claim/form/ClaimForm.vue'

const route = useRoute()
const appRouter = useAppRouter()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()
const spinnerStore = useSpinnerStore()
const flashesStore = useFlashesStore()

// ---------- FORM SUBMIT ----------

const formSubmitting = ref(false)
const formErrors = ref({})

function handleSubmit(attributes) {
  if (!formSubmitting.value) {
    formSubmitting.value = true
    const params = {
      data: {
        type: 'pointsofinterest',
        attributes: { ...attributes },
      },
    }

    claimPointOfInterest(pointOfInterest.value.id, params)
      .then(() => {
        appRouter.back()

        flashesStore.addFlash({
          message: t('points_of_interest.claim.success'),
          type: 'success',
        })
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- GET CURRENT POINT OF INTEREST ----------

const pointOfInterest = ref(null)

function fetchPoi() {
  spinnerStore.enable()

  const { id } = route.params

  fetchPointOfInterest(id)
    .then((response) => {
      pointOfInterest.value = response.data.data
    })
    .finally(() => {
      spinnerStore.disable()
    })
}

const l10nTitle = computed(() => (
  getAttributeTranslation(pointOfInterest.value.attributes.title)
))

// ---------- BREADCRUMBS ----------

const { claim } = breadcrumbPointOfInterest()

const breadcrumbs = computed(() => (
  claim({
    id: pointOfInterest.value?.id,
    label: l10nTitle.value,
  })
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPoi()
})
</script>
