import { ref } from 'vue'
import { defineStore } from 'pinia'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useDropdownStore = defineStore('dropdown', () => {
  const open = ref(false)

  function setState(state) {
    open.value = state
  }

  return {
    open,
    setState,
  }
})
