<template>
  <div>
    <points-of-interest-mode-filters
      v-if="mode === 'points_of_interest'"
      @filters-applied="handleFiltersApplied"
    />

    <existing-excursions-mode-filters
      v-else-if="mode === 'existing_excursions'"
      :edited-excursion="excursion"
      @filters-applied="handleFiltersApplied"
    />
  </div>
</template>

<script setup>
import { inject, watch } from 'vue'

import { useFiltersStore } from '@shared/store/filters'
import PointsOfInterestModeFilters from '@extranet/components/resources/excursion/main_form/steps/columns/filters/PointsOfInterestModeFilters.vue'
import ExistingExcursionsModeFilters from '@extranet/components/resources/excursion/main_form/steps/columns/filters/ExistingExcursionsModeFilters.vue'
import useForm from '@shared/hooks/form/form'

const props = defineProps({
  // Resource related to the main form
  excursion: {
    type: Object,
    default: () => ({}),
  },
  // Filters mode
  mode: {
    type: String,
    required: true,
  },
})

const emits = defineEmits([
  'filters-applied',
])

const filtersStore = useFiltersStore()

const {
  allFieldsToSubmit,
} = useForm(props, {
  initialValues: filtersStore.filters.pointsOfInterest,
  formName: 'pointsOfInterestIndexFiltersForm',
})

// Sync provided filters with submittable fields
const filters = inject('filters')
filters.value = allFieldsToSubmit.value

watch(allFieldsToSubmit, () => {
  filters.value = allFieldsToSubmit.value
})

function handleFiltersApplied() {
  emits('filters-applied', allFieldsToSubmit.value)
}
</script>
