<template>
  <div>
    <div
      class="flex flex-col md:flex-row flex-wrap
      justify-end items-start md:items-center
      space-y-2 md:space-y-0 md:space-x-2"
    >
      <app-button
        v-if="showAddButton"
        :label="t('common.actions.add_contact')"
        @click="addContact"
      />

      <template
        v-if="relation"
      >
        <template
          v-if="!isRelationSender && relation.attributes.state === 'pending'"
        >
          <app-button
            :label="t('common.actions.accept_contact')"
            @click="approveContact"
          />

          <app-button
            :label="t('common.actions.decline_contact')"
            @click="declineContact"
          />
        </template>
      </template>

      <app-button
        v-if="relation && relation.attributes.state !== 'blocked'"
        :label="t('common.actions.block_contact')"
        @click="handleBlock"
      />

      <report-action
        :resource="resource"
      />
    </div>

    <!-- Block modal -->
    <app-overlay
      :show="blockConfirmModalDisplayed"
      @clicked="handleBlockCancel"
    >
      <app-modal @closed="handleBlockCancel">
        <app-confirm
          confirm-color="red"
          @cancelled="handleBlockCancel"
          @confirmed="handleBlockConfirm"
        >
          <p>
            {{ t('users_relationships.messages.block.confirm') }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useSpinnerStore } from '@shared/store/spinner'
import { useFlashesStore } from '@shared/store/flashes'
import {
  addContact as addApiContact,
  approveContact as approveApiContact,
  declineContact as declineApiContact,
  blockContact as blockApiContact,
} from '@shared/http/api'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import ReportAction from '@shared/components/resources/user/actions/ReportAction.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'contactUpdated',
])

const authStore = useAuthStore()
const spinnerStore = useSpinnerStore()
const flashesStore = useFlashesStore()
const { t } = useI18n()

const relation = ref(props.resource.relationships.users_relationship)

const isRelationSender = computed(() => (
  relation.value && relation.value.attributes.sender_id === authStore.user.id
))

// ---------- BLOCK ----------

const blockConfirmModalDisplayed = ref(false)

function handleBlock() {
  blockConfirmModalDisplayed.value = true
}

function handleBlockCancel() {
  blockConfirmModalDisplayed.value = false
}

function handleBlockConfirm() {
  blockConfirmModalDisplayed.value = false
  blockContact()
}

function blockContact() {
  spinnerStore.enable()

  blockApiContact(relation.value.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.block.success'),
        type: 'success',
      })
    })
    .finally(() => {
      spinnerStore.disable()
      emits('contactUpdated')
    })
}

// ---------- ADD CONTACT ----------

const showAddButton = computed(() => (
  (
    !relation.value
    && props.resource.id !== authStore.user.id
  )
  || (
    relation.value
    && relation.value.attributes.state === 'declined'
    && relation.value?.attributes?.decline_expired_at_js_timestamp <= new Date().getTime()
  )
))

function addContact() {
  spinnerStore.enable()

  const params = {
    'id': relation.value ? relation.value.id : null,
    'recipient_id': props.resource.id,
  }

  addApiContact(params)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.add.success'),
        type: 'success',
      })
    })
    .catch((e) => {
      if (e.response.status !== 403) {
        flashesStore.addFlash({
          message: t('users_relationships.messages.add.error'),
          type: 'danger',
        })
      }
    })
    .finally(() => {
      spinnerStore.disable()
      emits('contactUpdated')
    })
}

// ---------- APPROVE ----------

function approveContact() {
  spinnerStore.enable()

  approveApiContact(relation.value.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.approve.success'),
        type: 'success',
      })
    })
    .finally(() => {
      spinnerStore.disable()
      emits('contactUpdated')
    })
}

// ---------- DECLINE ----------

function declineContact() {
  spinnerStore.enable()

  declineApiContact(relation.value.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.decline.success'),
        type: 'success',
      })
    })
    .finally(() => {
      spinnerStore.disable()
      emits('contactUpdated')
    })
}
</script>
