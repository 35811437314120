<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <template v-if="isEmailOrUnselectedMode">
      <form-group
        rules="required"
        class="with-border-on-control"
        name="email"
        :hint="t('shares.emails.space_hint')"
        :disabled="submitting"
        @changed="handleEmailChange"
      />

      <form-hint
        v-if="emails.length > 0"
        :hint="t('shares.emails.select_hint')"
      />

      <ul class="mb-4 text-sm">
        <li
          v-for="(email, i) in emails"
          :key="i"
          class="cursor-pointer"
          @click="setEmail(email)"
        >
          {{ email }}
        </li>
      </ul>

      <form-group
        name="locale"
        rules="required"
        type="select"
        :label="t('form.labels.traveller_invitation.locale')"
        :class="`${isEmailMode ? 'h-56' : ''}`"
        :form-control-props="{
          options: staticLocalesOptions,
        }"
      />
    </template>

    <app-separator
      v-if="isUnselectedMode"
      :text="t('auth.messages.or')"
      class="my-4"
    />

    <form-group
      v-if="isRecipientsOrUnselectedMode"
      rules="required"
      class="with-border-on-control"
      :class="isRecipientsMode ? 'h-56' : ''"
      :label="t('validation.attributes.recipients_alt')"
      name="recipients_ids"
      type="select"
      :form-control-props="{
        filterable: false,
        options: handleUsersSearch,
        mode: 'tags',
      }"
      @changed="handleRecipientsChange"
    />

    <invitation-link
      v-if="isUnselectedMode"
      :link="resource?.attributes?.share_url"
      :hint-title="t('shares.link.instructions')"
      margin-bottom="mb-4"
    />

    <app-button
      feature="confirm"
      type="submit"
      :disabled="submitting"
      :loading="submitting"
    />
  </form>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import {
  previousUsersShares,
  usersByTravellerFolders,
} from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useForm from '@shared/hooks/form/form'
import useShareForm from '@extranet/hooks/shareForm'
import { displayName } from '@shared/helpers/user'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import FormHint from '@shared/components/form/FormHint.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import InvitationLink from '@extranet/components/resources/invitation/InvitationLink.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not
  // generated by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const {
  handleSubmit,
  form,
} = useForm(props, { emits })
const { t } = useI18n()
const store = useStore()

// ---------- USERS OPTIONS ----------

function handleUsersSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    usersByTravellerFolders(store.state.auth.user.attributes.point_of_interest_id, {
      'search': searchKeywords,
      'mode': 'share',
    })
      .then((response) => {
        options = formatUsersOptions(response.data.data)
      })
      .finally(() => {
        resolve(options)
      })
  })
}

// Return users options used in select control,
// based on users resources
function formatUsersOptions(users) {
  return users.map((userResource) => ({
    label: userDisplay(userResource),
    value: userResource.id,
  }))
}

// Display user's name
// and/or user's email
function userDisplay(user) {
  const name = displayName(user)
  const email = user.attributes.email

  return name !== email
    ? `${name} (${email})`
    : email
}

// ---------- PREVIOUS SHARE ----------

const emails = ref([])

function shareEmails() {
  previousUsersShares()
    .then((response) => {
      emails.value = Object.values(response.data).slice(0, 3)
    })
}

function setEmail(value) {
  form.setFieldValue('email', value)
}

// ---------- FORM ----------

const {
  isUnselectedMode,
  isEmailOrUnselectedMode,
  isEmailMode,
  isRecipientsMode,
  isRecipientsOrUnselectedMode,
  handleRecipientsChange,
  handleEmailChange,
} = useShareForm(props, { emits })

// ---------- LOCALES ----------

const {
  staticLocalesOptions,
} = useLocale()

// ---------- LIFECYCLES ----------

onMounted(() => {
  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  shareEmails()
})
</script>
