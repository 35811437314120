<template>
  <div class="flex justify-between">
    <p class="font-bold text-xl">
      {{ t('tutorials.extranet.begin.shared.payments.description') }}
    </p>

    <app-tutorial-button
      :tutorials="tutorials"
    />
  </div>

  <div
    v-if="!hasAccount"
    class="text-left mt-4 w-8/12"
  >
    <p class="mb-2">
      {{ t('bank.account.lines.no_account_yet') }}
    </p>

    <add-bank-account-form
      :submitting="formPaymentSubmitting"
      @submitted="onSubmit"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import {
  updateUserBank,
} from '@shared/http/api'
import AddBankAccountForm from '@extranet/components/form/bank/AddBankAccountForm.vue'
import AppTutorialButton from '@extranet/components/ui/help/AppTutorialButton.vue'
import HelpBankAccount from '@extranet/components/begin/help/HelpBankAccount.vue'

const emits = defineEmits([
  'submitted',
])

const authStore = useAuthStore()
const flashesStore = useFlashesStore()
const { t } = useI18n()

// ---------- TUTORIALS ----------

const tutorials = computed(() => ([
  {
    menuItem: t('tutorials.extranet.begin.hotelier.steps.employees.title'),
    component: HelpBankAccount,
  },
]))

// ---------- CHECKS ----------

/**
 * Change the property in the API to check if the account is correctly populated.
 *
 */
const hasAccount = computed(() => (
  authStore.user.resource?.attributes?.has_easy_transac_account
))

// ---------- SUBMIT PAYLOAD ----------

const formPaymentSubmitting = ref(false)
const formPaymentErrors = ref({})

async function onSubmit(attributes) {
  if (!formPaymentSubmitting.value) {
    formPaymentSubmitting.value = true

    const params = {
      data: {
        type: 'users',
        attributes: { ...attributes },
      },
    }

    /**
     * Push bank information in API.
     */
    updateUserBank(params)
      .then(() => {
        flashesStore.addFlash({
          message: t('tutorials.extranet.begin.messages.payment'),
          type: 'success',
        })

        handleRefresh()
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formPaymentErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formPaymentSubmitting.value = false
      })
  }
}

// ---------- REFRESH MODAL ----------

async function handleRefresh() {
  await authStore.getAuthenticatedUser()
    .then(() => {
      emits('submitted')
    })
}
</script>
