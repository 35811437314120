import { snakeCase } from 'lodash'

export default function useEnum() {
  /**
   * Return snake & to "upper-cased" key; By reference of php's enums.
   *
   * @param value
   * @returns {string}
   */
  const fromPHPEnum = (value) => snakeCase(value).toUpperCase()

  return {
    fromPHPEnum,
  }
}
