<template>
  <div>
    <div class="flex flex-col md:flex-row items-center mb-8">
      <!-- Filters modes -->
      <app-button
        v-for="(mode, i) in modes"
        :key="i"
        :label="t(`excursions.form.${mode}`)"
        rounded="rounded-md"
        font-color-classes="text-theme-900"
        :background-classes="selectedMode === mode ? 'bg-gray-300' : 'bg-transparent'"
        shadow="shadow-none"
        class="mr-3"
        @click="handleModeChange(mode)"
      />

      <!-- Create POI shortcut -->
      <app-button
        :label="t('extranet.points_of_interest.actions.new')"
        emphasis="low"
        feature="add"
        :icon-props="{ size: 'text-2xl' }"
        :hide-icon="false"
        class="flex-row-reverse"
        @click="displayCreationModal"
      />
    </div>

    <!-- Columns -->
    <div class="grid gap-8 grid-cols-1 md:grid-cols-3 mt-5">
      <column-filters
        :mode="selectedMode"
        :excursion="resource"
        @filters-applied="handleFiltersApplied"
      />

      <column-pick
        :filters="filters"
        :force-refresh="forceRefresh"
        @point-of-interest-selected="handlePointOfInterestSelected"
      />

      <column-drop
        :resource="resource"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  provide,
  inject,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { max } from 'lodash'

import { useModalsStore } from '@shared/store/modals'
import { useResourcesStore } from '@shared/store/resources'
import AppButton from '@shared/components/ui/AppButton.vue'
import ColumnDrop from '@extranet/components/resources/excursion/main_form/steps/columns/ColumnDrop.vue'
import ColumnFilters from '@extranet/components/resources/excursion/main_form/steps/columns/ColumnFilters.vue'
import ColumnPick from '@extranet/components/resources/excursion/main_form/steps/columns/ColumnPick.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Mode to select
  forceMode: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const modalsStore = useModalsStore()
const resourcesStore = useResourcesStore()
const form = inject('form', ref({}))

// ---------- FILTERS ----------

// Sync filters with index
const filters = ref({})
provide('filters', filters)

// Force index to refresh when filters applied
const forceRefresh = ref(1)
function handleFiltersApplied(allFieldsToSubmit) {
  filters.value = allFieldsToSubmit
  forceRefresh.value += 1
}

// ---------- MODE ----------

const modes = [
  'points_of_interest',
  'existing_excursions',
]

// Select mode from props, or first one as default
const selectedMode = ref(props.forceMode ?? modes[0])

function handleModeChange(value) {
  selectedMode.value = value
}

// ---------- POI CREATION ----------

function displayCreationModal() {
  modalsStore.setProperties({
    name: 'point_of_interest_form',
    display: true,
    on_submit_success: (response) => {
      // Close modal
      modalsStore.reset('point_of_interest_form')

      const resource = response.data.data
      // Make the resource available in the store
      resourcesStore.push({
        type: 'pointsOfInterest',
        resources: [resource],
      })

      // Select the point of interest
      handlePointOfInterestSelected(resource.id)
    },
  })
}

// "excursions_points_of_interest" form's value
const excursionsPointsOfInterest = computed(() => (
  form.value.values.excursions_points_of_interest || []
))

// Add POI to the final column
function handlePointOfInterestSelected(id) {
  // Calc the new item position
  const positions = excursionsPointsOfInterest.value.map((item) => item.position)
  const maxPosition = max(positions) || 0
  const newPosition = maxPosition + 1
  const newIndex = excursionsPointsOfInterest.value.length

  // Push the new item to the list
  form.value.setFieldValue(`excursions_points_of_interest.${newIndex}`, {
    point_of_interest_id: id,
    position: newPosition,
  })
}
</script>
