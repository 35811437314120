<template>
  <!-- Search -->
  <form-control-text
    name="excursions_search"
    :icon-props="{ library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' }"
    :placeholder="t('excursions.index.search')"
    :enter-event-handler="(e) => e.preventDefault()"
    class="mb-5"
    @changed="handleSearch"
  />

  <existing-excursions-filters
    @filters-applied="handleFiltersApplied"
  />

  <template v-if="!loading">
    <!-- Excursions list -->
    <template
      v-if="selectableExcursions.length > 0"
    >
      <template
        v-for="(selectableExcursion, i) in selectableExcursions"
        :key="i"
      >
        <app-card-item
          :class="`my-4 cursor-pointer duration-300 ${selectableExcursion.id === selectedExcursionId ? 'text-white' : ''}`"
          :background-classes="`${selectableExcursion.id === selectedExcursionId ? 'bg-theme-500' : 'bg-white'}`"
          @click="emits('excursion-selected', selectableExcursion.id)"
        >
          <excursion-card
            :resource="selectableExcursion"
            :with-link="false"
            hide-actions
          />
        </app-card-item>
      </template>
    </template>

    <p
      v-else-if="searchedOnce"
      class="mt-8 text-center"
    >
      {{ t('excursions.search.no_results') }}
    </p>
  </template>

  <!-- Loading -->
  <template v-else>
    <excursion-card-empty
      v-for="i in 7"
      :key="i"
      class="my-4"
    />
  </template>

  <!-- Next page -->
  <div class="mt-5">
    <template v-if="lastPage && page < lastPage">
      <a
        v-if="nextPageFetching === false"
        href="#"
        class="block text-center app-link w-full"
        @click.prevent="nextPage"
      >
        {{ t('common.actions.load_more') }}
      </a>
    </template>

    <div
      v-if="nextPageFetching"
      class="text-center w-full"
    >
      <app-spinner
        class="text-theme-500"
        size="lg"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { useFiltersStore } from '@shared/store/filters'
import {
  fetchExcursions as fetchApiExcursions,
} from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import ExcursionCard from '@extranet/components/resources/excursion/ExcursionCard.vue'
import ExcursionCardEmpty from '@extranet/components/resources/excursion/ExcursionCardEmpty.vue'
import ExistingExcursionsFilters from '@extranet/components/resources/excursion/main_form/steps/columns/filters/existing_excursions/ExistingExcursionsFilters.vue'

const props = defineProps({
  // Resource related to the main form
  editedExcursion: {
    type: Object,
    default: () => ({}),
  },
  // Currently selected excursion's id
  selectedExcursionId: {
    type: Number,
    default: null,
  },
})

const emits = defineEmits([
  'excursion-selected',
])

const filtersStore = useFiltersStore()
const { t } = useI18n()

// ---------- EXCURSIONS INDEX ----------

const excursions = ref([])
const nextPageFetching = ref(false)
const page = ref(1)
const lastPage = ref(null)
const loading = ref(false)
const searchedOnce = ref(false)

// except' currently edited excursion
const selectableExcursions = computed(() => (
  excursions.value.filter((excursion) => (
    props.editedExcursion.id !== excursion.id
  ))
))

// ---------- FILTERS ----------

const {
  allFieldsToSubmit,
} = useForm(props, {
  initialValues: filtersStore.filters.excursions,
  formName: 'excursionsIndexFiltersForm',
})

function handleFiltersApplied() {
  resetPage()
  fetchExcursions()
}

onMounted(() => {
  fetchExcursions()
})

// ---------- FETCH ----------

function fetchExcursions(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'source': 'extranet',
    'filters': allFieldsToSubmit.value,
  }

  fetchApiExcursions(params)
    .then((response) => {
      const oldExcursions = excursions.value
      const newExcursions = response.data.data

      if (nextPage) {
        // add new results after the old ones
        excursions.value = [...oldExcursions, ...newExcursions]
      } else {
        // replace the old results with the new ones
        excursions.value = newExcursions
      }

      lastPage.value = response.data.meta?.last_page
      if (!searchedOnce.value) {
        searchedOnce.value = true
      }
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

function resetPage() {
  page.value = 1
}

function nextPage() {
  page.value += 1
  fetchExcursions(true)
}

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchExcursions()
  }, 400)
}

</script>
