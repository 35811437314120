<template>
  <app-overlay
    show
    @clicked="handleModalClose"
  >
    <app-modal
      max-width-classes="max-w-screen-2xl"
      text-align-classes="text-left"
      @closed="handleModalClose"
    >
      <p class="font-bold text-3xl text-center mb-6">
        {{ t('extranet.points_of_interest.actions.new') }}
      </p>

      <point-of-interest-form
        :resource="pointOfInterest"
        :additional-errors="pointOfInterestFormErrors"
        :submitting="pointOfInterestFormSubmitting"
        @submitted="handleSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'

import { useModalsStore } from '@shared/store/modals'
import { useFlashesStore } from '@shared/store/flashes'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import PointOfInterestForm from '@extranet/components/resources/point_of_interest/PointOfInterestForm.vue'
import usePointOfInterestForm from '@shared/hooks/resources/form/pointOfInterest'

const { t } = useI18n()
const modalsStore = useModalsStore()
const flashesStore = useFlashesStore()

const {
  resource: pointOfInterest,
  errors: pointOfInterestFormErrors,
  submitting: pointOfInterestFormSubmitting,
  handleSubmit,
} = usePointOfInterestForm({
  onSubmitSuccess,
})

const pointsOfInterestIndexRefresh = inject('points_of_interest_index_refresh')

function onSubmitSuccess(response) {
  // Use callback from store, or default one
  if (modalsStore.modals.point_of_interest_form.on_submit_success) {
    modalsStore.modals.point_of_interest_form.on_submit_success(response)
  } else {
    handleModalClose()
    flashesStore.addFlash({
      message: t('points_of_interest.store.success'),
      type: 'success',
    })

    pointsOfInterestIndexRefresh.value += 1
  }
}

function handleModalClose() {
  modalsStore.reset('point_of_interest_form')
}
</script>
