<template>
  <!-- Actions -->
  <excursion-actions
    :resource="resource"
    :show-labels="true"
    class="mb-4"
    @destroyed="destroyCallback"
  />

  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <!-- Excursion -->
    <div>
      <div class="mb-4 lg:mr-2">
        <div class="flex items-center justify-between">
          <app-section-title
            :title="t('excursions.pages.show.sections.excursion')"
          />
        </div>
      </div>

      <app-card-item>
        <app-data-grid
          :data="excursionData"
          :loading="loading"
        />
      </app-card-item>
    </div>

    <!-- Points of interest -->
    <div class="col-start-1 col-span-2 mt-4">
      <div class="flex justify-between w-full md:w-1/2">
        <app-section-title
          :title="t('excursions.pages.show.sections.pois')"
          class="mb-3.5"
        />

        <span class="h-fit bg-gradient-to-br from-theme-500 to-theme-700 text-white text-xs font-semibold inline-flex items-center p-1.5 rounded-full md:mr-2">
          {{ resource?.attributes.points_of_interest_count }}
        </span>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div
          v-if="!loading && pointsOfInterest.length > 0"
        >
          <app-card-item
            v-for="(pointOfInterest, i) in pointsOfInterest"
            :key="i"
            class="p-4 mb-4 cursor-pointer"
            @click="handlePointOfInterestClicked(pointOfInterest)"
          >
            <point-of-interest-card
              :resource="pointOfInterest"
              :with-link="false"
            />
          </app-card-item>
        </div>

        <app-list-placeholder
          v-else
          :quantity="5"
          :component="PointOfInterestCardEmpty"
          :loading="loading"
        />

        <!-- Embed Map -->
        <app-embed-direction-map
          v-if="directionCoordinates.length >= 2"
          :coordinates="directionCoordinates"
          class="w-full max-w-screen-sm mx-auto"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize, upperCase } from 'lodash'

import { useModalsStore } from '@shared/store/modals'
import useExcursion from '@shared/hooks/resources/excursion'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppDataGrid from '@extranet/components/ui/data_grid/AppDataGrid.vue'
import AppEmbedDirectionMap from '@shared/components/ui/map/direction/AppEmbedDirectionMap.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppListPlaceholder from '@extranet/components/ui/AppListPlaceholder.vue'
import PointOfInterestCard from '@extranet/components/resources/point_of_interest/PointOfInterestCard.vue'
import PointOfInterestCardEmpty from '@extranet/components/resources/point_of_interest/PointOfInterestCardEmpty.vue'
import ExcursionActions from '@extranet/components/resources/excursion/ExcursionActions.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()
const modalsStore = useModalsStore()

const {
  directionCoordinates,
  pointsOfInterest,
  l10nTitle,
} = useExcursion(props)

// ---------- EXCURSION ----------

const excursionData = computed(() => (
  [
    {
      label: t('validation.attributes.title'),
      data: l10nTitle.value,
    },
    {
      label: t('validation.attributes.kind'),
      data: t(`enums.Excursion.KindEnum.${upperCase(props.resource.attributes.kind)}`),
    },
    {
      label: t('validation.attributes.favorite'),
      data: capitalize(t(`common.${props.resource.attributes.favorite}`)),
    },
  ]
))

// ---------- POINTS OF INTEREST ----------

function handlePointOfInterestClicked(pointOfInterest) {
  modalsStore.setProperties({
    name: 'point_of_interest_show',
    display: true,
    id: pointOfInterest.id,
  })
}
</script>
